import * as AccessibleIcon from '@radix-ui/react-accessible-icon';

const Triangle = () => {
	return (
		<AccessibleIcon.Root label='Video Play Icon'>
			<svg xmlns='http://www.w3.org/2000/svg' width='18' height='20' viewBox='0 0 18 20' fill='none'>
				<g filter='url(#filter0_d_14347_55026)'>
					<path d='M2 2L12 8L2 14V2Z' fill='white' />
				</g>
				<defs>
					<filter id='filter0_d_14347_55026' x='0' y='0' width='18' height='20' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'>
						<feFlood flood-opacity='0' result='BackgroundImageFix' />
						<feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
						<feOffset dx='2' dy='2' />
						<feGaussianBlur stdDeviation='2' />
						<feComposite in2='hardAlpha' operator='out' />
						<feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0' />
						<feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_14347_55026' />
						<feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_14347_55026' result='shape' />
					</filter>
				</defs>
			</svg>
		</AccessibleIcon.Root>
	)
}

export default Triangle;
