import { forwardRef } from 'react';
import { useRouter } from 'next/router';
import { Caption, Title } from '@components/typography';
import { ALTERNATE_PREMIUM_PLUS_COPY, BF_LENSES_STEP_TITLE, LENSES_PACKAGES, LOCALE_DICT } from '@constants';
import { BASE_FRAME_LENS_OPTIONS } from '@utils/constants/base-skus';
import { getVariantPriceDiff } from '@services/shopify/hooks/useBaseFrameVariant';
import { Checkbox, EditabilityLensBody, Flex } from '@components';
import { useIsLensExpansionTest } from '@utils/hooks';
import { useBFContext } from '@context';
import styles from './CustomLenses.module.scss';

type CustomLensesProps = {
	[k: string]: any;
};

const CustomLenses = forwardRef<HTMLDivElement>((props: CustomLensesProps, ref) => {
	const { locale } = useRouter();
	const { isLensExpansion, isLensExpansionExpA, isLensExpansionExpB } = useIsLensExpansionTest();
	const {
		selectedLenses,
		lensPackage,
		BaseFrameVariant,
		variantPrices,
		lensOptions,
		rxType,
		handleCustomLens,
	} = useBFContext();

	const currentLensOptions = lensOptions.find(option => option.optionValue === rxType)?.lenses ?? [];
	const preloadLensOptions = currentLensOptions.length > 0 ? currentLensOptions : lensOptions?.[0]?.lenses;

	return (
		<Flex column gap={3} style={{ opacity: 1, paddingTop: '1.6rem' }} ref={ref}>
			<Flex column style={{ height: '5.6rem' }}>
				<Title style={{ alignContent: 'center' }}>{BF_LENSES_STEP_TITLE.replace('2', '3')}</Title>
				<Caption small>(Select all that apply)</Caption>
			</Flex>
			{/*Removes Premium Plus from options*/}
			{/*TODO extract to it's own component or reuse another component*/}
			{preloadLensOptions
				.filter(({ optionValue }) => { 
					const removePremiumPlus = isLensExpansionExpA && optionValue === BASE_FRAME_LENS_OPTIONS.PREMIUM_PLUS;
					const removeLightResponsive = lensPackage === LENSES_PACKAGES.BASIC && optionValue === BASE_FRAME_LENS_OPTIONS.LIGHT_RESPONSIVE;

					if(removePremiumPlus || removeLightResponsive) {
						return false
					}

					return true
				})
				.map(lens => {
					const isBlueLightOption = lens.optionValue === BASE_FRAME_LENS_OPTIONS.BLUE_LIGHT;
					const isLightResponsiveSelected = selectedLenses.includes(BASE_FRAME_LENS_OPTIONS.LIGHT_RESPONSIVE);
					const variantPriceDiff = BaseFrameVariant.state['Rx Type'] && getVariantPriceDiff({
						lookup: variantPrices,
						current: BaseFrameVariant,
						selectedLenses,
						lens: lens.optionValue,
						currencyCode: LOCALE_DICT[locale].currencyCode,
						locale,
						lensPackage,
					});

					const description = isLensExpansionExpB && lens.optionValue === BASE_FRAME_LENS_OPTIONS.PREMIUM_PLUS 
						? ALTERNATE_PREMIUM_PLUS_COPY
						: lens.description; 

					return (
						<Checkbox
							dataTags={{ 'data-lens-type': lens.optionValue }}
							key={lens.name}
							option={lens.name}
							checked={selectedLenses.includes(lens.optionValue) || selectedLenses.includes(BASE_FRAME_LENS_OPTIONS.LIGHT_RESPONSIVE) && lens.optionValue === BASE_FRAME_LENS_OPTIONS.BLUE_LIGHT}
							hoverable={isLensExpansion ? true : !!lensPackage}
							hoverColor='gray'
							className={!!lensPackage ? styles['lens-checkbox'] : ''}
							handler={() => {
								if (!!lensPackage) {
									handleCustomLens(lens.optionValue);
								}
							}}
							setChecked={selectedLenses.includes(BASE_FRAME_LENS_OPTIONS.LIGHT_RESPONSIVE) && lens.optionValue === BASE_FRAME_LENS_OPTIONS.BLUE_LIGHT ? () => null : null}
						>
							<EditabilityLensBody
								selectedLenses={selectedLenses}
								name={lens.name}
								tag={lens.tag}
								price={isBlueLightOption && isLightResponsiveSelected
									? {
										amount: 0,
										currencyCode: LOCALE_DICT[locale].currencyCode,
										locale,
									}
									: BaseFrameVariant.data.variantBySelectedOptions.title.includes(lens.optionValue)
										? variantPriceDiff?.without
										: variantPriceDiff?.with
								}
								copy={description}
								usePlusSign
							/>
						</Checkbox>
					);
				})}
		</Flex>
	);
});

CustomLenses.displayName = 'CustomLenses';

export default CustomLenses;
