import { useEffect, useMemo } from 'react';
import { useRefinementList } from 'react-instantsearch';
import { useFilterContext } from '@context';
import { RefinementSwatch, RefinementOption } from '@components/search';
import { RefinementListProps, normalizeRefinementItems, transformItems } from '@utils/algolia';
import RefinementListAccordion from '../RefinementListAccordion/RefinementListAccordion';

function RefinementList({ listType = 'accordion', className, setIsDisabled = null, ...props }: RefinementListProps) {
	const { attribute, operator, limit, showMore, showMoreLimit, type } = props;
	const { collections, isSunglassesRoute } = useFilterContext();
	const { items, refine } = useRefinementList({
		attribute,
		operator,
		limit: showMore ? showMoreLimit : limit,
		transformItems,
	});
	const { refine: refineOptions, items: itemsOptions } = useRefinementList({
		attribute: 'option_names',
	})

	const handleOnRefine = (value: string) => {
		if(type === 'product_type' && value === 'frame'){
			refineOptions('frame');
			return;
		}
		refine(value);
	};

	
	const allItems = useMemo(() => {
		return attribute == 'product_type'? items.concat(itemsOptions): items;
	}, [items, itemsOptions, attribute]);


	let optionsMap = normalizeRefinementItems({ type, collections, items: allItems, isSunglasses: isSunglassesRoute });
	if (type === 'collections' || type === 'color') {
		optionsMap = new Map([...optionsMap].sort());
	}

	if(type === 'product_type'){
		optionsMap = new Map([...optionsMap].sort((a, b) => {
			if(a[0] > b[0]) return -1;
			if(a[0] < b[0]) return 1;
			return 0;
		}));
	}

	const refinementOptions = [];
	for (const [label, item] of optionsMap.entries()) {
		const { value, isRefined } = item;

		// If the refinement label is not found in the active collections,
		// then we would rather not render it
		if (label) {
			if (type === 'color') {
				refinementOptions.push(
					<RefinementSwatch key={label} isRefined={isRefined} label={label} type={type} refine={handleOnRefine} value={value} />
				);
			} else {
				refinementOptions.push(
					<RefinementOption key={label} isRefined={isRefined} label={label} type={type} refine={handleOnRefine} value={value} />
				);
			}
		}
	}

	// Disables the refinement list if there are no options
	useEffect(() => {
		if (typeof setIsDisabled === 'function') setIsDisabled(refinementOptions.length === 0);
	}, [refinementOptions.length, setIsDisabled]);

	const refinementListJsx =
		listType === 'accordion' ? (
			refinementOptions.length ? (
				<RefinementListAccordion refinementOptions={refinementOptions} isInitiallyOpened={type === 'color'} {...props} />
			) : null
		) : (
			<>
				<ul className={className}>{refinementOptions.slice(0, limit)}</ul>
				<div />
			</>
		);

	return !!items.length ? refinementListJsx : null;
}

export default RefinementList;
