import {
	PropsWithChildren,
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react';
import cn from 'classnames'
import { useCurrentRefinements, useInstantSearch } from 'react-instantsearch';
import { Button, RefinementList } from '@components';
import { RefinementListProps, getAttributeRefinements } from '@utils/algolia';
import { useClickOutside, useLockedBody, useMediaQuery } from '@utils/hooks';
import variables from '@styles/export.module.scss';
import styles from './FacetDropdown.module.scss';

import type { UiState } from 'instantsearch.js';
import type { SearchResults } from 'algoliasearch-helper';
import type { CurrentRefinementsConnectorParamsRefinement } from 'instantsearch.js/es/connectors/current-refinements/connectCurrentRefinements';

export type DropdownProps = PropsWithChildren<{
	buttonText?: string | ((options: {
		results: SearchResults;
		uiState: UiState;
		refinements: CurrentRefinementsConnectorParamsRefinement[];
	}) => string);
	classNames?: Partial<{
		root: string;
		button: string;
		buttonRefined: string;
		closeButton: string;
		mobileTitle: string;
	}>;
	closeOnChange?: boolean | (() => boolean);
	refinementListProps: RefinementListProps;
}>;

type MiddlewareProps = Pick<DropdownProps, 'closeOnChange'> & {
	isOpened: boolean;
	close: () => void;
};

function DropdownMiddleware({
	isOpened,
	closeOnChange,
	close,
}: MiddlewareProps) {
	const { addMiddlewares } = useInstantSearch();

	useEffect(() =>
		addMiddlewares(() => ({
			onStateChange() {
				const shouldCloseOnChange =
					closeOnChange === true ||
					(typeof closeOnChange === 'function' && closeOnChange() === true);

				// Close the dropdown if it's opened and `closeOnChange` is true
				if (isOpened && shouldCloseOnChange) {
					close();
				}
			},
		}))
	);

	return null;
}

const FacetDropdown = ({
	buttonText,
	closeOnChange,
	refinementListProps,
}: DropdownProps) => {
	const { ...rest } = useInstantSearch();
	const { items } = useCurrentRefinements();
	const [isOpened, setIsOpened] = useState(false);
	const [isDisabled, setIsDisabled] = useState(false);
	const panelRef = useRef(null);

	// Close the dropdown when click outside or press the Escape key
	const close = useCallback(() => setIsOpened(false), []);
	useClickOutside(panelRef, close, isOpened);

	// Prevent scrolling on mobile when the dropdown is opened
	const isMobile = useMediaQuery('(max-width: 375px)');
	useLockedBody(isOpened && isMobile);

	const refinements = getAttributeRefinements(refinementListProps.attribute, items);

	// Get the header button text
	let text: string;
	if (typeof buttonText === 'string') {
		text = buttonText;
	} else if (typeof buttonText === 'function') {
		text = buttonText({ results: rest.results, uiState: rest.uiState, refinements });
	}

	return (
		<div className={styles['root']} ref={panelRef}>
			<DropdownMiddleware
				isOpened={isOpened}
				closeOnChange={closeOnChange}
				close={close}
			/>
			<Button
				color='white'
				extraClasses={cn(
					styles['button'],
					isOpened && styles['is-opened'],
					isDisabled && styles['is-disabled']
				)}
				onClick={() => setIsOpened((opened) => !opened)}
				disabled={isDisabled}
				title={isDisabled ? 'No available filter options' : `Open ${text} dropdown`}
				fullWidth
				spaceBetween
				removeEffects
				withChevron
				chevronColor={isDisabled ? 'currentColor' : variables.blue2}
				chevronDirection={isOpened ? 'up' : 'down'}
			>
				{text}
				{!!refinements?.length ? <span className={styles['count']}>{refinements.length}</span> : null}
			</Button>
			<RefinementList
				className={cn(styles['list'], { [styles['is-opened']]: isOpened })}
				listType='dropdown'
				setIsDisabled={setIsDisabled}
				{...refinementListProps}
			/>
		</div>
	);
}

export default FacetDropdown;
