import { useRouter } from "next/router";
import { Chevron, Flex, Lozenge, NonRx, Progressive, Readers, SingleVision } from "@components";
import variables from "@styles/export.module.scss";
import { RX_TYPE } from "@utils/constants/base-skus";
import { Paragraph, Title } from "@components/typography";
import { LensOption } from "@ts/components";
import { useIsLensExpansionTest } from "@utils/hooks";
import { useBFContext } from "@context";
import { formatCurrency } from "@utils/shopify";
import { BUILD_FLOW_STEPS_LENS_EXPANSION, LOCALE_CODES } from "@constants";
import styles from "./PrescriptionLensExpansion.module.scss";

type RxOptionsProps = {
	option: LensOption;
	variantPriceDiff: {
		with: { amount: number, locale: typeof LOCALE_CODES[keyof typeof LOCALE_CODES], currencyCode: string };
		without: { amount: number, locale: typeof LOCALE_CODES[keyof typeof LOCALE_CODES], currencyCode: string };
	}
}

const PrescriptionIcons = {
	'single-vision': ({ className }) => <SingleVision className={className} />,
	'non-prescription': ({ className }) => <NonRx className={className} />,
	'readers': ({ className }) => <Readers className={className} />,
	'progressives': ({ className }) => <Progressive className={className} />,
}

const RxOptions = ({ option, variantPriceDiff }: RxOptionsProps) => {
	const { locale } = useRouter();
	const { rxType, step } = useBFContext();
	const isPrescriptionStep = step === BUILD_FLOW_STEPS_LENS_EXPANSION.LENS;
	const showCurr = locale === LOCALE_CODES.AU || locale === LOCALE_CODES.CA;
	const { isLensExpansion } = useIsLensExpansionTest();
	const isOptionSelected = rxType === option.optionValue;
	const isProgressiveSelected = rxType === RX_TYPE.PROGRESSIVE;
	const additionalPrice = isOptionSelected ? variantPriceDiff.without : variantPriceDiff.with;
	const priceCopy = `+${formatCurrency(additionalPrice, showCurr)}`;

	return (
		<Flex fullHeight fullWidth>
			{PrescriptionIcons[option.id]({ className: styles.rxIconLensExpansion })}
			<Flex
				align='center'
				gap={2}
				fullWidth
				style={{ flexGrow: 1, backgroundColor: 'transparent', padding: '1.2rem', borderRadius: '0.4rem' }}
			>
				<Flex column gap={1} align='start' style={{ flexGrow: 1 }}>
					<Flex align='center' gap={2} position={'relative'} fullWidth>
						{(option.optionValue === RX_TYPE.PROGRESSIVE && !isProgressiveSelected) &&
							<Lozenge text='Our Specialty' shape='square' color={variables.green2} backgroundColor={variables.greenLight} />
						}
						<div style={{ flexGrow: 1 }} />
						{isLensExpansion && <Paragraph className={styles['price']}>{priceCopy}</Paragraph>}
					</Flex >
					{isOptionSelected && isLensExpansion ? <Lozenge text='Selected' shape='square' color={variables.green2} backgroundColor={variables.greenLight} /> : null}
					<Title>{option.copy}</Title>
					<Paragraph style={{ color: variables.gray4 }}>{option.description}</Paragraph>
				</Flex >
				{isLensExpansion && (isPrescriptionStep || !isOptionSelected) && (
					<div className={styles.chevronContainer}>
						<Chevron direction='right' color={variables.blue2} />
					</div>
				)}
			</Flex >
		</Flex >
	);
};

export default RxOptions;
