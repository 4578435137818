import AccountCard, { AccountCardProps } from '../AccountCard/AccountCard';

const OrderDetail = ({ order, ...rest

}: Omit<AccountCardProps, 'children'>) => {
	if (!order) return null;

	return (
		<AccountCard
			order={order}
			title='RECENT ORDER'
			label={`Ordered on ${order?.date}`}
			subtitle={`ORDER #${order?.order_number}`}
			{...rest}
		/>
	);
};

export default OrderDetail;
