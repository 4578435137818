const Progressive = ({ className }) => {
	return <svg viewBox='0 0 96 96' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
		<path fillRule='evenodd' clipRule='evenodd' d='M53.8992 20.1638C67.9046 23.8151 80.8841 28.2672 84.0183 37.9678C85.6371 42.9794 83.804 50.8178 81.0678 56.8743C75.2438 69.7659 67.4791 76.8237 53.6728 76.4952C48.7382 76.3789 42.18 75.4017 36.7117 73.3C32.8604 71.8201 29.453 70.7004 26.446 68.9127C17.4433 63.5631 14.5939 54.4192 13.476 44.7067C12.634 37.3928 13.3179 26.6565 19.2278 21.2389C25.2591 15.7094 35.5065 16.8845 45.7925 18.3936L53.8992 20.1638Z' fill='#C4EAFF'/>
		<g opacity='0.4'>
			<path d='M78.0016 39.7751C76.7151 44.5763 63.7796 45.2818 49.1093 41.3509C34.439 37.42 23.5892 30.3412 24.8757 25.54C26.1622 20.7388 39.0977 20.0333 53.768 23.9642C68.4384 27.8951 79.2881 34.9739 78.0016 39.7751Z' fill='#84D0FC'/>
			<path d='M78.0016 39.7751C76.7151 44.5763 63.7796 45.2818 49.1093 41.3509C34.439 37.42 23.5892 30.3412 24.8757 25.54C26.1622 20.7388 39.0977 20.0333 53.768 23.9642C68.4384 27.8951 79.2881 34.9739 78.0016 39.7751Z' fill='#36208F' fillOpacity='0.1'/>
			<path d='M71.2724 64.8892C69.4142 71.8243 56.2313 74.3176 41.8277 70.4581C27.4242 66.5987 17.2542 57.8481 19.1124 50.913C20.9706 43.9779 34.1535 41.4846 48.557 45.3441C62.9606 49.2035 73.1306 57.9542 71.2724 64.8892Z' fill='#84D0FC'/>
			<path d='M71.2724 64.8892C69.4142 71.8243 56.2313 74.3176 41.8277 70.4581C27.4242 66.5987 17.2542 57.8481 19.1124 50.913C20.9706 43.9779 34.1535 41.4846 48.557 45.3441C62.9606 49.2035 73.1306 57.9542 71.2724 64.8892Z' fill='#36208F' fillOpacity='0.1'/>
			<g style={{ mixBlendMode: 'soft-light' }} opacity='0.6'>
				<path fillRule='evenodd' clipRule='evenodd' d='M30.7539 71.3437L75.4985 27.3207C74.3769 26.6861 73.1872 26.0896 71.9408 25.5255L27.1941 69.5504C28.3236 70.2244 29.5095 70.8041 30.7539 71.3437Z' fill='white'/>
			</g>
			<g style={{ mixBlendMode: 'soft-light' }} opacity='0.8'>
				<path fillRule='evenodd' clipRule='evenodd' d='M48.564 77.1342L85.3045 40.9862C85.2134 40.0416 85.0434 39.1518 84.7838 38.336C83.7846 35.1972 81.7765 32.6024 79.0474 30.4046L35.116 73.6275C35.7394 73.8617 36.375 74.1013 37.0231 74.3511C40.6528 75.7503 44.7609 76.6518 48.564 77.1342Z' fill='white'/>
			</g>
		</g>
		<path fillRule='evenodd' clipRule='evenodd' d='M33.9144 72.2442C31.187 71.2199 28.7038 70.2543 26.4462 68.9121C25.2272 68.1878 24.121 67.3939 23.1167 66.5384C26.4611 65.8166 29.6564 64.4718 32.5302 62.558L32.904 62.3091L32.8747 62.7575C32.6643 65.9713 33.0214 69.1813 33.9144 72.2442Z' fill='black'/>
		<path d='M68.7383 6.48166L68.7631 6.86018C69.0515 11.2651 68.0768 15.6612 65.9537 19.5316C70.3644 19.618 74.6582 20.9629 78.3302 23.4082L78.6457 23.6183L78.621 23.2398C78.3325 18.8349 79.3072 14.4387 81.4303 10.5683C77.0196 10.482 72.7258 9.13707 69.0538 6.69179L68.7383 6.48166Z' fill='#AAE0FE'/>
		<path d='M12.8041 67.7281L12.7945 67.874C12.6833 69.5725 13.0591 71.2676 13.8778 72.7599C12.1771 72.7932 10.5214 73.3118 9.10558 74.2546L8.98391 74.3357L8.99347 74.1897C9.10468 72.4913 8.72886 70.7962 7.91023 69.3038C9.61091 69.2705 11.2666 68.7519 12.6824 67.8091L12.8041 67.7281Z' fill='#AAE0FE'/>
		<path d='M74 86C74 87.1046 62.1355 88 47.5 88C32.8645 88 21 87.1046 21 86C21 84.8954 32.8645 84 47.5 84C62.1355 84 74 84.8954 74 86Z' fill='#EFEDEE'/>
		<path d='M29.9039 61.3091L29.8745 61.7575C29.5328 66.9757 30.6875 72.1836 33.2026 76.7686C27.9775 76.8709 22.8909 78.4641 18.5409 81.3609L18.1671 81.6098L18.1965 81.1614C18.5382 75.9432 17.3835 70.7353 14.8684 66.1503C20.0935 66.048 25.1801 64.4548 29.5301 61.5581L29.9039 61.3091Z' fill='#FF510E'/>
		<path d='M84.1725 15.8372L84.1826 15.9924C84.3009 17.7981 83.9013 19.6003 83.031 21.187C84.8391 21.2224 86.5993 21.7737 88.1046 22.7761L88.234 22.8623L88.2238 22.7071C88.1056 20.9013 88.5051 19.0992 89.3755 17.5125C87.5674 17.4771 85.8071 16.9258 84.3018 15.9234L84.1725 15.8372Z' fill='#FF510E'/>
		<path d='M79.6344 71.223L79.6924 71.3189C80.3681 72.4355 81.3484 73.3362 82.5181 73.9151C81.4635 74.6826 80.6503 75.7353 80.174 76.9496L80.1331 77.0539L80.0751 76.958C79.3994 75.8414 78.4191 74.9407 77.2494 74.3618C78.304 73.5943 79.1172 72.5416 79.5934 71.3273L79.6344 71.223Z' fill='#FF510E'/>
	</svg>

}

export default Progressive;
