import { useRef } from 'react';
import { m } from 'framer-motion';
import cn from 'classnames';
import { useRouter } from 'next/router';
import { useBFContext } from '@context';
import { useIsLensExpansionTest } from '@utils/hooks';
import { getVariantPriceDiff } from '@services/shopify/hooks/useBaseFrameVariant';
import { RX_TYPE } from '@utils/constants/base-skus';
import { BUILD_FLOW_STEP_DIRECTION, BUILD_FLOW_STEPS_LENS_EXPANSION, CUSTOMER_JOURNEYS, LOCALE_DICT } from '@constants';
import { buildFlowPrescriptionVariantsLensExpansion } from '@utils/motions';
import { CustomLenses, Flex, LensPackages } from '@components';
import { LensOption } from '@ts/components';
import RxOptions from "./RxOptions";
import ReadersStrength from './ReadersStrength';
import styles from './PrescriptionLensExpansion.module.scss';

type PrescriptionProps = {
	option: LensOption;
	index: number;
}

const PrescriptionLensExpansion = ({ option, index }: PrescriptionProps) => {
	const packageStepRef = useRef<HTMLDivElement>(null);
	const customizeStepRef = useRef<HTMLDivElement>(null);
	const {
		setRxType,
		readerRx,
		rxType,
		step,
		journey,
		variantPrices,
		BaseFrameVariant,
		setStepChange,
		lensPackage,
		setStep,
		setLensPackage,
		setSelectedLenses,
	} = useBFContext();
	const { locale } = useRouter();
	const ref = useRef<HTMLDivElement>();
	const { isLensExpansion } = useIsLensExpansionTest();
	const isPrescriptionStep = step === BUILD_FLOW_STEPS_LENS_EXPANSION.LENS;
	const isLensesStep = step === BUILD_FLOW_STEPS_LENS_EXPANSION.CUSTOMIZE || step === BUILD_FLOW_STEPS_LENS_EXPANSION.PACKAGE;
	const isReadersSelected = rxType === RX_TYPE.READERS;
	const isSunLens = journey === CUSTOMER_JOURNEYS.SUNGLASSES;

	const isOptionSelected = rxType === option.optionValue;
	const showReaderStrength = isLensExpansion && isReadersSelected && isOptionSelected && !isPrescriptionStep;

	const variantPriceDiff = getVariantPriceDiff({
		lookup: variantPrices,
		current: BaseFrameVariant,
		rxType: option.optionValue,
		sunLensException: isSunLens,
		locale,
		currencyCode: LOCALE_DICT[locale].currencyCode,
	});

	const classes = cn(
		{
			[styles['rx-list-item']]: isLensExpansion,
			[styles['hover-disabled']]: isLensesStep,
			[styles['is-readers']]: isReadersSelected,
		}
	);

	let animate = 'initial';
	let initial = 'initial';
	if (isLensesStep) {
		animate = isOptionSelected && (isReadersSelected || !isSunLens) ? 'active' : 'inactive';
		initial = isOptionSelected ? 'active' : 'inactive';
	}

	const useOpacity = isReadersSelected ? !!readerRx : !!rxType;

	return (
		<>
			{isLensExpansion ? (
				<m.div
					ref={ref}
					key={option.id}
					id={option.id}
					initial={initial}
					data-prescription-type={option.copy}
					animate={animate}
					variants={buildFlowPrescriptionVariantsLensExpansion(index, !!ref?.current ? (ref?.current.offsetTop) : 0)}
					transition={{ type: 'tween', duration: 0.25 }}
					custom={index}
					onClick={() => {
						if (!rxType || rxType !== option.optionValue) {
							setRxType(option.optionValue);
							setLensPackage(null);
							setSelectedLenses([]);
							if (isLensExpansion && (!rxType || rxType !== option.optionValue)) setStepChange(BUILD_FLOW_STEP_DIRECTION.NEXT);
						} else if (!!rxType && rxType === option.optionValue && isPrescriptionStep) {
							setStepChange(BUILD_FLOW_STEP_DIRECTION.NEXT);
						} else if (isLensesStep) {
							setStep(BUILD_FLOW_STEPS_LENS_EXPANSION.LENS);
						}
					}}
				>
					<m.div className={classes} initial={initial} animate={animate} variants={{ active: { opacity: useOpacity ? 0.5 : 1 } }} transition={{ type: 'spring', delay: 0.5 }}>
						<RxOptions option={option} variantPriceDiff={variantPriceDiff} />
						{showReaderStrength && <ReadersStrength />}
					</m.div>
				</m.div >
			) : (
				<>
					<div
						key={option.id}
						id={option.id}
						className={classes}
						data-prescription-type={option.copy}
						onClick={() => {
							setRxType(option.optionValue);
							setLensPackage(null);
							setSelectedLenses([]);
						}}
					>
						<RxOptions option={option} variantPriceDiff={variantPriceDiff} />
						{showReaderStrength && <ReadersStrength />}
					</div>
				</>
			)}
			{isLensExpansion && (
				!!rxType && isOptionSelected && !isPrescriptionStep && (
					<m.div
						variants={{
							hidden: {
								y: -(ref?.current?.offsetTop - 8),
								opacity: 0,
							},
							show: {
								opacity: 1,
							},
						}}
						initial='hidden'
						animate='show'
						transition={{ type: 'spring', delay: 0.5 }}
					>
						<Flex column>
							{rxType && (!isReadersSelected) && <LensPackages ref={packageStepRef} customizeStepRef={customizeStepRef} />}
							{rxType && isReadersSelected && readerRx && <LensPackages ref={packageStepRef} customizeStepRef={customizeStepRef} />}
							{rxType && lensPackage && (
								<CustomLenses ref={customizeStepRef} />
							)}
						</Flex>
					</m.div>
				)
			)}
		</>
	);
};

export default PrescriptionLensExpansion;
