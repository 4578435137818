const PremiumPlus = ({ className }) => {
	return <svg viewBox='0 0 96 96' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
		<path d='M75.4283 42.9836L61.2527 28.3913L20.6428 37.921L15.9858 56.9327L53.2795 82.2269L75.4283 42.9836Z' fill='black'/>
		<path d='M83.2724 33.5182L66.6035 16.9748L19.9328 28.8299L14.9586 50.8711L58.5356 79.2793L83.2724 33.5182Z' fill='#FF510E'/>
		<path d='M59.8506 39.4676L83.2725 33.5181L58.5357 79.2791L59.8506 39.4676Z' fill='#CB3800'/>
		<path d='M38.3809 44.9215L14.959 50.8711L58.536 79.2793L38.3809 44.9215Z' fill='#CB3800'/>
		<path d='M83.2724 33.5182L66.6035 16.9748L60.8264 39.2199L83.2724 33.5182Z' fill='#FF7E4D'/>
		<path d='M37.4042 45.1696L19.7594 28.874L14.9583 50.8713L37.4042 45.1696Z' fill='#FF7E4D'/>
		<path d='M60.8266 39.2199L44.1577 22.6764L37.4047 45.1694L60.8266 39.2199Z' fill='#CB3800'/>
		<path d='M28.6187 72.8466L28.554 72.9928C27.8007 74.6937 26.5775 76.1444 25.0283 77.1744C26.6581 78.0688 27.9983 79.4102 28.8912 81.0409L28.9679 81.181L29.0327 81.0349C29.786 79.334 31.0092 77.8832 32.5584 76.8532C30.9286 75.9588 29.5884 74.6174 28.6955 72.9868L28.6187 72.8466Z' fill='#FF510E'/>
		<path d='M77.8858 15.1606L77.8211 15.3068C77.0678 17.0076 75.8446 18.4584 74.2954 19.4884C75.9252 20.3828 77.2654 21.7242 78.1583 23.3549L78.235 23.495L78.2998 23.3488C79.0531 21.6479 80.2763 20.1972 81.8255 19.1672C80.1957 18.2728 78.8555 16.9314 77.9626 15.3007L77.8858 15.1606Z' fill='#B5DEF4'/>
		<path d='M77.379 68.0908L77.1181 68.3224C74.082 71.0173 70.3395 72.79 66.3309 73.4321C68.7569 76.6839 70.203 80.561 70.4991 84.6073L70.5246 84.9551L70.7855 84.7235C73.8216 82.0286 77.5641 80.2558 81.5728 79.6138C79.1467 76.362 77.7006 72.4849 77.4045 68.4385L77.379 68.0908Z' fill='#B5DEF4'/>
		<path fill-rule='evenodd' clip-rule='evenodd' d='M17.075 41.1721C18.2009 41.9838 19.2643 42.8881 20.2532 43.8784L20.5528 44.1784L20.6091 43.7579C21.1135 39.9936 22.4365 36.3999 24.4688 33.2234L19.7592 28.874L17.075 41.1721Z' fill='black'/>
		<path d='M16.4722 22.206L16.4159 22.6264C15.7603 27.5191 13.7219 32.1235 10.5405 35.8982C15.3568 36.9659 19.7674 39.3876 23.2535 42.8784L23.553 43.1784L23.6094 42.7579C24.265 37.8653 26.3033 33.2609 29.4847 29.4862C24.6684 28.4185 20.2579 25.9968 16.7718 22.506L16.4722 22.206Z' fill='#B5DEF4'/>
	</svg>
	
}

export default PremiumPlus
