import { useRef, useState } from 'react';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { NavigationOptions } from 'swiper/types';
import { Chevron, ConditionalLink, Flex, InstagramIcon, Spacer, Testimonial, TikTokIcon, YouTubeIcon } from '@components';
import { Heading, Caption } from '@components/typography';
import { useContentfulAsset } from '@services/contentful/hooks';
import { useIsMobile } from '@utils/hooks';
import { InteractiveCardEntrySkeletonType } from '@ts/contentful';
import { MEDIUM_MAX_WIDTH } from '@constants';

import styles from './Testimonials.module.scss';

const Testimonials = ({ heading, subheading, testimonialSlide, page, unoptimized, slug, customer }) => {
	const navigationPrevRef = useRef(null);
	const navigationNextRef = useRef(null);
	const [isBeginning, setIsBeginning] = useState(false);
	const [isEnd, setIsEnd] = useState(false);
	const [isMuted, setIsMuted] = useState(true);
	const isMobile = useIsMobile({ maxWidth: MEDIUM_MAX_WIDTH });

	const navigationRefs = {
		prevEl: navigationPrevRef.current,
		nextEl: navigationNextRef.current,
	};

	const { data } = useContentfulAsset<InteractiveCardEntrySkeletonType>({
		id: `testimonialSlider-${slug}`,
		query: {
			'content_type': 'testimonialSlider',
			'fields.slug': slug,
		},
	});

	if (!!data) {
		testimonialSlide = data[0]?.fields?.testimonialSlide ?? [];
	}

	if (!testimonialSlide.length) return null;
	if (!customer && String(slug).includes('logged-in')) return null;
	if (customer && String(slug).includes('logged-out')) return null;

	return (
		<div className={styles.container} data-testimonial-slider data-component-type='testimonials' data-page-type={page}>
			<Flex column align='start' className={styles.copy}>
				<Heading data-testid='testimonials-heading' tag={isMobile ? 'h4' : 'h3'} fixedSize>{heading}</Heading>
				<Caption data-testid='testimonials-caption' style={{ fontWeight: '400' }}>{subheading}</Caption>
				<Flex className={styles.socialContainer}>
					<ul data-testid='social-icons' className={styles.socialList}>
						<li>
							<a href='https://www.facebook.com/paireyewear/'>
								<svg viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
									<path d='M2.00195 12.0721C2.00312 16.9915 5.58036 21.1801 10.439 21.9511V14.9621H7.90195V12.0721H10.442V9.87211C10.3284 8.82965 10.6845 7.79071 11.4136 7.03704C12.1427 6.28338 13.1693 5.89313 14.215 5.97211C14.9655 5.98423 15.7141 6.05108 16.455 6.17211V8.63111H15.191C14.7558 8.57412 14.3183 8.71784 14.0017 9.02178C13.6851 9.32572 13.5237 9.757 13.563 10.1941V12.0721H16.334L15.891 14.9631H13.563V21.9511C18.8174 21.1207 22.502 16.3219 21.9475 11.0312C21.3929 5.74048 16.7932 1.81004 11.4808 2.08729C6.16831 2.36454 2.0028 6.75242 2.00195 12.0721Z' fill='black'/>
								</svg>
							</a>
						</li>
						<li>
							<a href='https://www.tiktok.com/@paireyewear/'>
								<TikTokIcon />
							</a>
						</li>
						<li>
							<a href='https://www.instagram.com/paireyewear/'>
								<InstagramIcon />
							</a>
						</li>
						<li>
							<a href='https://www.youtube.com/channel/UCsfEDry8WF9MRqYaCkQRipw'>
								<YouTubeIcon />
							</a>
						</li>
					</ul>
				</Flex>
			</Flex>
			<Swiper
				className={styles.swiper}
				data-testid='testimonials'
				direction='horizontal'
				spaceBetween={8}
				slidesPerView='auto'
				speed={500}
				modules={[Navigation]}
				navigation={navigationRefs}
				onSlideChange={swiper => {
					setIsBeginning(swiper.isBeginning);
					setIsEnd(swiper.isEnd);
				}}
				onSlidesGridLengthChange={swiper => {
					setIsBeginning(swiper.isBeginning);
					setIsEnd(swiper.isEnd);
					swiper.on('reachEnd', () => {
						setIsEnd(true);
					});
				}}
				onBeforeInit={swiper => {
					(swiper.params.navigation as NavigationOptions).prevEl = navigationPrevRef.current;
					(swiper.params.navigation as NavigationOptions).nextEl = navigationNextRef.current;
				}}
			>
				<ul>
					{testimonialSlide.map((testimonial, index) => (
						<SwiperSlide key={`${testimonial.text}-${index}`} className={styles['container__swiper-slide']}>
							<ConditionalLink
								href={testimonial.featuredProductUrl}
								condition={!!testimonial.featuredProductUrl}
								reduceOpacityOnHover
							>
								<Testimonial unoptimized={!!unoptimized} {...testimonial} isMuted={isMuted} setIsMuted={setIsMuted} />
							</ConditionalLink>
						</SwiperSlide>
					))}
				</ul>
			</Swiper>
			<div className={styles.navigationButtonsContainer}>
				<div className={`${styles.prevButton} ${isBeginning ? styles.disable : ''}`} ref={navigationPrevRef}>
					<Chevron extraClasses={styles.chevron} direction='left' />
				</div>
				<div className={`${styles.nextButton} ${isEnd ? styles.disable : ''}`} ref={navigationNextRef}>
					<Chevron extraClasses={styles.chevron} direction='right' />
				</div>
			</div>
			<Spacer size='3.2rem' />
			<div className={styles.divider}></div>
		</div>
	);
};

export default Testimonials;
