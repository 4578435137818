import { useCallback } from 'react';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useRouter } from 'next/router';
import {
	BF_LENSES_STEP_TITLE,
	BF_PRESCRIPTIONS_STEP_TITLE,
	BUILD_FLOW_STEPS,
	BUILD_FLOW_STEPS_LENS_EXPANSION,
	CUSTOMER_JOURNEYS,
	LENSES_PACKAGES,
} from '@constants';
import { EducationModal, Flex, Lenses, Prescriptions, PrescriptionsLensExpansion } from '@components';
import { Caption, Title } from '@components/typography';
import { useBFContext } from '@context';
import { BASE_FRAME_LENS_OPTIONS, RX_TYPE } from '@utils/constants/base-skus';
import { DEFAULT_INFO_TABS, LENS_INFO_TABS, VARIFOCAL_INFO_TABS } from '@utils/constants/info-module';
import { useIsLensExpansionTest } from '@utils/hooks';
import styles from './PrescriptionStep.module.scss';

const PrescriptionStep = () => {
	const { rxType, step, journey, lensOptions, lensPackage } = useBFContext();
	const { locale } = useRouter();
	const isSunLens = journey === CUSTOMER_JOURNEYS.SUNGLASSES;
	const { isLensExpansion: isLensExp } = useIsLensExpansionTest();
	const isVarifocalCopyTestActive = useFeatureIsOn('is-progressives-copy-test');
	const isLensExpansion = isLensExp && !isSunLens;
	const bfSteps = isLensExpansion ? BUILD_FLOW_STEPS_LENS_EXPANSION : BUILD_FLOW_STEPS;
	const isCollectionsStep = step === bfSteps.TOP_FRAMES;
	const isPackagesStep = step === BUILD_FLOW_STEPS_LENS_EXPANSION.PACKAGE;
	const isPrescriptionsStep = step === bfSteps.LENS || (isLensExpansion && (step === bfSteps.LENS || isPackagesStep || step === BUILD_FLOW_STEPS_LENS_EXPANSION.CUSTOMIZE))
	const isLensesStep = step === bfSteps.CUSTOMIZE;
	const isReadersSelected = rxType === RX_TYPE.READERS;
	const shouldDisplayLenses = (isLensesStep && !isLensExpansion || isCollectionsStep) && (isReadersSelected || !isSunLens);
	const currentLensOptions = lensOptions.find(option => option.optionValue === rxType || option.optionValue === RX_TYPE.SINGLE_VISION).lenses

	const getEducationTabs = useCallback(() => {
		const tabs = LENS_INFO_TABS;
		const filteredOptions = currentLensOptions.filter(option => {
			if (isLensExpansion) {
				if (option.name === 'Premium Plus') {
					return false;
				}
				if (lensPackage === LENSES_PACKAGES.BASIC && option.name === BASE_FRAME_LENS_OPTIONS.LIGHT_RESPONSIVE) {
					return false;
				}
			}
			return true;
		});

		return filteredOptions.map((option) => {
			return tabs.find(tab => tab.title.includes(option.name));
		});
	}, [currentLensOptions, lensPackage, isLensExpansion]);

	return (
		<div className={styles.container}>
			{
				<Flex align='center' justify='between' gap={3} className={styles.title}>
					<Flex column>
						<Title style={{ opacity: isLensExpansion && step !== BUILD_FLOW_STEPS_LENS_EXPANSION.LENS && !isSunLens ? 0.5 : 1 }}>{isPrescriptionsStep || isSunLens ? BF_PRESCRIPTIONS_STEP_TITLE : BF_LENSES_STEP_TITLE}</Title>
						{!(isPrescriptionsStep || isSunLens) && <Caption small>(Select all that apply)</Caption>}
					</Flex>
					{!isPackagesStep ? (
						<div onClick={e => e.stopPropagation()}>
							{isLensesStep && !isSunLens
								?
								<EducationModal
									tabs={!isLensExpansion ? getEducationTabs() : [...DEFAULT_INFO_TABS, ...getEducationTabs()]}
									title={'The Handy Lens Add-Ons'}
									tab={0}
									subTitle={'Enhance your Pair with these lens extras!'}
								/>
								:
								<EducationModal
									tabs={isVarifocalCopyTestActive && locale === 'en-GB' ? VARIFOCAL_INFO_TABS : DEFAULT_INFO_TABS}
									title={'The Rx Lens Lineup'}
									tab={0}
									subTitle={'Here’s our prescription lens lowdown!'}
								/>
							}
						</div>
					) : null}
				</Flex>
			}
			<div className={styles['options-container']}>
				{isLensExpansion && !isSunLens ? <PrescriptionsLensExpansion /> : (
					<>
						<Prescriptions />
						{shouldDisplayLenses && <Lenses />}
					</>
				)}
			</div>
		</div>
	);
};

export default PrescriptionStep;
