import { useState } from 'react';
import Link from 'next/link';
import { SubmitHandler, useForm } from 'react-hook-form';
import cn from 'classnames';
import { useRouter } from 'next/router';
import { EMAIL_VALIDATION_REG_EX, NEXT_APP_ENV } from '@constants';
import { Alert, Button, Chevron, Input } from '@components';
import { Heading } from '@components/typography';
import createCustomer from '@services/shopify/operations/create-customer';
import useLogin from '@services/shopify/operations/login';
import { trackRegister } from '@services/analytics/trackers';
import { CustomerCreateInput, CustomerErrorCode } from '@ts/shopify-storefront-api';
import linkStyles from '../Link/Link.module.scss';
import styles from './RegisterForm.module.scss';

const RegisterForm = () => {
	const router = useRouter();
	const login = useLogin();
	const linkStyleClasses = cn(linkStyles['link'], styles.backLink, [linkStyles['link--small']]);
	const [submitErrors, setSubmitErrors] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [isAccountCreated, setIsAccountCreated] = useState(false);
	const isProd = process.env.NEXT_PUBLIC_APP_ENV === NEXT_APP_ENV.PROD;
	const { previous } = router.query;

	const {
		register,
		handleSubmit,
		reset,
		watch,
		formState: { errors, isDirty, isValid },
	} = useForm<CustomerCreateInput>({
		mode: 'all',
		defaultValues: {
			email: '',
			password: '',
		},
	});

	const onSubmit: SubmitHandler<CustomerCreateInput> = async data => {
		setIsLoading(true);
		try {
			await createCustomer(data);
			setIsLoading(false);
			setIsAccountCreated(true);
			setSubmitErrors([]);
			if (isProd) trackRegister(data);
			reset();
		} catch (error) {
			setIsLoading(false);
			setIsAccountCreated(false);
			reset();
			return setSubmitErrors(error.errors);
		}

		await login(data);
		router.push('/');
		reset();
	};

	const currentEmailInput = watch('email');
	const currentPasswordInput = watch('password');

	return (
		<form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
			<Link href={previous as string || '/account/login'}>
				<div className={linkStyleClasses} style={{ display: 'flex', alignItems: 'center', marginTop: '-1rem' }}>
					<Chevron direction='left' /> Go Back
				</div>
			</Link>
			<Heading tag='h1' style={{ marginBottom: '2.4rem', fontSize: '4.8rem', lineHeight: '5rem' }}>
				Create Account
			</Heading>
			{!!submitErrors.length &&
				submitErrors.map((error, index) => (
					// First time clients (without an account) will get a disabled customer error,
					// so we need to inform them that an invitation is on the way
					<Alert
						key={index}
						info={error?.code === CustomerErrorCode.CustomerDisabled}
						message={error.message.toString()}
					/>
				))}
			{isAccountCreated && <Alert info message='Your account is being created!' />}
			<div className={styles.nameLine}>
				<Input
					{...register('firstName', {
						required: 'Please fill out this field.',
					})}
					errorMessage={errors?.firstName?.message}
					id='CustomerFirstName'
					name='firstName'
					placeholder='First Name'
					type='text'
					required
				/>
				<Input
					{...register('lastName', {
						required: 'Please fill out this field.',
					})}
					errorMessage={errors?.lastName?.message}
					id='CustomerLastName'
					name='lastName'
					placeholder='Last Name'
					type='text'
					required
				/>
			</div>
			<Input
				{...register('email', {
					required: 'Please fill out this field.',
					pattern: {
						value: EMAIL_VALIDATION_REG_EX,
						message: `Please include an '@' in the email address. ${currentEmailInput} is missing an '@'.`,
					},
				})}
				errorMessage={errors?.email?.message}
				id='RegisterCustomerEmail'
				name='email'
				placeholder='Email'
				type='email'
				required
			/>
			<Input
				{...register('password', {
					required: 'Please fill out this field.',
					minLength: {
						value: 5,
						message: `Please lengthen this text to 5 characters or more (you are currently using ${currentPasswordInput.length} characters).`,
					},
				})}
				errorMessage={errors?.password?.message}
				id='RegisterCustomerPassword'
				name='password'
				placeholder='Password'
				type='password'
				required
			/>

			<div className={styles.buttons}>
				<Button showSpinner={isLoading} fullWidth type='submit' label='Create' disabled={!isDirty || !isValid} />
			</div>
		</form>
	);
};

export default RegisterForm;
