import { useState } from "react";
import cn from 'classnames';
import { m, AnimatePresence } from "framer-motion";
import { Label } from "@radix-ui/react-dropdown-menu";
import { Accordian, Button, Flex, Minus, Plus } from "@components";
import { RefinementListProps } from "@utils/algolia";
import styles from './RefinementListAccordion.module.scss';

type RefinementListAccordionProps = RefinementListProps & { refinementOptions: JSX.Element[]; isInitiallyOpened?: boolean; };

function RefinementListAccordion({
	attribute,
	type,
	label,
	limit,
	refinementOptions,
	isInitiallyOpened = false,
}: RefinementListAccordionProps) {
	const [isOpened, setOpened] = useState(isInitiallyOpened);
	const [isShowingMore, setIsShowingMore] = useState(false);
	const listClasses = cn(styles.list, {
		[styles.swatches]: type === 'color',
	})

	return (
		<Accordian className={styles['container']} title={label || attribute} isOpen={isOpened} onClick={() => setOpened(!isOpened)}>
			<Flex column className={styles['options']}>
				<m.ul
					initial={{ visibility: 'hidden' }}
					animate={{ visibility: 'visible' }}
					exit={{ visibility: 'hidden' }}
					className={listClasses}
				>
					{refinementOptions.slice(0, limit)}
				</m.ul>
				<AnimatePresence>
					{isShowingMore && (
						<m.ul
							initial={{ height: 0, visibility: 'hidden' }}
							animate={{ height: 'auto', visibility: 'visible' }}
							exit={{ height: 0, visibility: 'hidden' }}
							transition={{ type: 'spring', duration: 0.6 }}
						>
							{refinementOptions.slice(limit)}
						</m.ul>
					)}
				</AnimatePresence>
				{(refinementOptions.length > limit) && (
					<Button
						onClick={() => setIsShowingMore(!isShowingMore)}
						linkStyle
						size='small'
						fitContent
						extraClasses={styles['button']}
					>
						{isShowingMore ? <Minus /> : <Plus />}
						{isShowingMore ? <Label>View Less</Label> : <Label>View More</Label>}
					</Button>
				)}
			</Flex>
		</Accordian>
	)
}

export default RefinementListAccordion;
