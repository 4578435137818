import * as AccessibleIcon from '@radix-ui/react-accessible-icon';
import variables from '@styles/export.module.scss';

type TagProps = {
	[k: string]: any;
};

const Tag = ({ width = 15, height = 14, color = variables.gray3, label }: TagProps) => {
	return (
		<AccessibleIcon.Root label={label}>
			<svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill='none'>
				<path
					d='M13.5 1.00012H14.125V0.375122H13.5V1.00012ZM7.50072 1.00012V0.375122C7.33925 0.375122 7.18405 0.437613 7.06763 0.549502L7.50072 1.00012ZM2.00808 6.27908L1.57499 5.82846L1.57499 5.82846L2.00808 6.27908ZM1.94823 8.60443L1.49253 9.03218H1.49253L1.94823 8.60443ZM5.58401 12.4778L5.12831 12.9055H5.12831L5.58401 12.4778ZM7.95875 12.5177L8.39981 12.9605V12.9605L7.95875 12.5177ZM13.5 6.99851L13.9411 7.44133C14.0588 7.32405 14.125 7.1647 14.125 6.99851H13.5ZM13.5 0.375122H7.50072V1.62512H13.5V0.375122ZM7.06763 0.549502L1.57499 5.82846L2.44117 6.7297L7.93381 1.45074L7.06763 0.549502ZM1.49253 9.03218L5.12831 12.9055L6.0397 12.05L2.40393 8.17669L1.49253 9.03218ZM8.39981 12.9605L13.9411 7.44133L13.0589 6.55569L7.51769 12.0749L8.39981 12.9605ZM14.125 6.99851V1.00012H12.875V6.99851H14.125ZM5.12831 12.9055C6.00823 13.8429 7.48888 13.8678 8.39981 12.9605L7.51769 12.0749C7.10617 12.4848 6.43721 12.4735 6.0397 12.05L5.12831 12.9055ZM1.57499 5.82846C0.674806 6.69363 0.63804 8.12185 1.49253 9.03218L2.40393 8.17669C2.01798 7.76552 2.03458 7.12048 2.44117 6.7297L1.57499 5.82846Z'
					fill={color}
					style={{
						fill: color,
						fillOpacity: 1,
					}}
				/>
				<path
					d='M10.5 5.00012C10.5 5.55241 10.0523 6.00012 9.5 6.00012C8.94772 6.00012 8.5 5.55241 8.5 5.00012C8.5 4.44784 8.94772 4.00012 9.5 4.00012C10.0523 4.00012 10.5 4.44784 10.5 5.00012Z'
					fill={color}
					style={{
						fill: color,
						fillOpacity: 1,
					}}
				/>
			</svg>
		</AccessibleIcon.Root>
	);
};

export default Tag;
