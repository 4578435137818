import { useMemo, useState } from 'react';
import { Accordian, Button, Spacer } from '@components';
import { Body, Heading } from '@components/typography';
import { ContentfulRichTextRenderer } from '@components/utils';
import { useHasMounted, useIsMobile } from '@utils/hooks';
import styles from './Faqs.module.scss';

type FaqsProps = {
	heading: string;
	text: string;
	buttonText: string;
	buttonLink: string;
	faqItems: {
		question: string;
		answer: string;
	}[];
	page?: string;
};

const Faqs = ({ heading, text, buttonText, buttonLink, faqItems, page }: FaqsProps) => {
	const isMobile = useIsMobile();
	const hasMounted = useHasMounted();
	const [activeIndex, setActiveIndex] = useState(-1);

	const Questions = useMemo(() => {
		const isOpen = (index: number) => activeIndex === index;

		const toggleActive = (index: number) => {
			if (activeIndex === index) {
				setActiveIndex(-1);
			} else {
				setActiveIndex(index);
			}
		};

		return faqItems.map((faqItem, index) => (
			<Accordian
				key={faqItem.question}
				title={faqItem.question}
				titleFormat={isMobile && hasMounted ? 'paragraph' : 'caption'}
				isOpen={isOpen(index)}
				onClick={() => toggleActive(index)}
				className={styles.itemContainer}
				customRow={styles.row}
			>
				{ContentfulRichTextRenderer(false, { text: faqItem.answer, maxWidth: 'Auto', blockMargin: 'Small', alignment: 'left' })}
			</Accordian>
		));
	}, [activeIndex, faqItems, isMobile, hasMounted]);

	return (
		<div className={styles['index-faq']} data-faqs data-component-type='frequentlyAskedQuestions' data-page-type={page}>
			<div className={styles['index-faq__container']}>
				<div className={styles['index-faq__header']}>
					<div className={styles['index-faq__header__text']}>
						<Heading tag={'h3'}>{heading}</Heading>
						<Body>{text}</Body>
					</div>
					<Button extraClasses={styles['index-faq__header__button-desktop']} size='small' color='white' href={buttonLink}>{buttonText}</Button>
				</div>
				<Spacer size='2.4rem' />
				<div className={styles['index-faq__content']}>{Questions}</div>
				<Spacer size='1.6rem' />
				<Button extraClasses={styles['index-faq__button-mobile']} size='small' color='white' href={buttonLink}>{buttonText}</Button>
			</div>
		</div>
	);
};

export default Faqs;
