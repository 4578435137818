import cn from 'classnames';
import { Button, Flex, Loading } from '@components';
import variables from '@styles/export.module.scss';
import { OrderDetailPOMS } from '@ts/poms/orders';
import { RewardDetail } from '@ts/yotpo/rewards';
import { FAQ_URL } from '@constants';
import { Paragraph, Caption, TypographyButton } from '@components/typography';
import styles from './AccountCard.module.scss';
import { OrderTracking } from './OrderTracking';
import { OrderDetail } from './OrderDetail';
import { RewardsDetail } from './RewardsDetail';

export type AccountCardProps = {
	order?: OrderDetailPOMS;
	rewardDetail?: RewardDetail,
	primaryAction?: 'none' | 'details' | 'rewards';
	secondaryAction?: 'none' | 'question';
	title?: string;
	label?: string;
	subCopy?: string;
	subtitle?: string;
	type?: 'details' | 'tracking' | 'rewards';
	className?: string;
	isDataLoading?: boolean;
};

const AccountCard = ({
	rewardDetail,
	primaryAction = 'details',
	secondaryAction = 'none',
	title,
	label,
	subCopy,
	subtitle,
	type = 'details',
	order,
	className,
	isDataLoading,
}: Omit<AccountCardProps, 'children'>) => {
	const classes = cn(styles.cardContainer, className);

	const content = (type) => {
		switch (type) {
			case 'details':
				return <OrderDetail order={order} />
			case 'tracking':
				return <OrderTracking order={order} />
			case 'rewards':
				return <RewardsDetail rewardDetail={rewardDetail} />
			default:
				return null
		}
	}

	return (
		<Flex column className={classes} justify='between' data-account-card={type}>
			{isDataLoading ?
				<Loading className={styles['loading-spinner']} /> :
				<>
					<div>
						<Copy title={title} label={label} subCopy={subCopy} subtitle={subtitle} type={type} />
						<Flex fullWidth column style={{ padding: '1.6rem 0' }}>
							{content(type)}
						</Flex>
					</div>
					<div>
						<ButtonGroup primaryAction={primaryAction} secondaryAction={secondaryAction} order={order} />
					</div>
				</>
			}
		</Flex>
	);
};

type CopyProps = {
	title?: string;
	label?: string;
	subCopy?: string;
	subtitle?: string;
	type?: 'details' | 'tracking' | 'rewards';
};

const Copy = ({ title, label, subCopy, subtitle, type }: CopyProps) => {
	const classes = cn(styles.label, {
		[styles.rewardsLabel]: type === 'rewards',
		[styles.ordersLabel]: type === 'details' || type === 'tracking',
	});

	return (
		<Flex fullWidth column>
			<Flex justify='between' align='center'>
				<Paragraph style={{ fontWeight: '500' }}>{title}</Paragraph>
				{subtitle && (
					<Caption small color={variables.gray3} style={{ height: '2rem' }}> {/* Inline style allows for centering, this font sits offcenter within its own line height  */}
						{subtitle}
					</Caption>
				)}
			</Flex>
			{label && <div className={classes}><TypographyButton small >{label}</TypographyButton></div>}
			{subCopy && <Paragraph>{subCopy}</Paragraph>}
		</Flex>
	);
};

type ButtonGroupProps = Required<Pick<AccountCardProps, 'primaryAction' | 'secondaryAction' | 'order'>>;

const ButtonGroup = ({ primaryAction, secondaryAction, order }: ButtonGroupProps) => {
	return (
		<Flex column fullWidth>
			{primaryAction === 'details' && <Button label='View Order Details' href={`/account/orders?orderNumber=${order ? order.order_number : ''}`} color='white' size='small' />}
			{primaryAction === 'rewards' && <Button label='Go to My Rewards' href={'/account/rewards'} color='white' size='small' />}
			{secondaryAction === 'question' && (
				<Button linkStyle href={`${FAQ_URL}/articles/4419012035351`} style={{ margin: '0.8rem auto', maxWidth: 'fit-content', fontSize: '1.4rem' }}>
					How Do I Redeem My Points?
				</Button>
			)}
		</Flex>
	);
};

export default AccountCard;
