import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { RewardDetail } from '@ts/yotpo/rewards';

const useRewards = (userId: string): UseQueryResult<RewardDetail> => {
	return useQuery<RewardDetail>(
		['rewardDetail', userId],
		async () => {
			const response = await fetch('api/rewards', {
				method: 'POST',
				headers: { 'accept': 'application/json', 'content-type': 'application/json' },
				body: JSON.stringify({ customerId: userId }),
			});
			const data = await response.json();
			return data;
		},
		{
			enabled: !!userId,
		}
	);
};

export default useRewards;
