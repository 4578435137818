import { Label } from '@components/typography';
import { useFilterContext } from '@context';
import {
	MARVEL_COLLECTION_LABEL,
	SPARKLE_COLLECTION_LABEL,
	getMarvelRefinements,
	getSparkleRefinements,
} from '@utils/algolia';
import variables from '@styles/export.module.scss';
import styles from './RefinementChip.module.scss';

const RefinementChip = ({ children, refine, items, item }) => {
	const { scrollToTop } = useFilterContext();

	return (
		<button
			className={styles['container']}
			onClick={() => {
				scrollToTop();
				if (item.value == MARVEL_COLLECTION_LABEL) {
					const marvelRefinements = getMarvelRefinements(items);
					marvelRefinements.forEach((item) => {
						refine(item);
					});
				} else if (item.value == SPARKLE_COLLECTION_LABEL) {
					const sparkleRefinements = getSparkleRefinements(items);
					sparkleRefinements.forEach((item) => {
						refine(item);
					});
				} else {
					refine(item);
				}
			}}
			title='Remove Option'
		>
			<Label color={variables.gray4}>{children}</Label>
			<svg className={styles['close-icon']} width={16} height={16} viewBox={`0 0 ${16} ${16}`} fill='none'>
				<path d='M12 4.5L4 12.5' strokeWidth='2' />
				<path d='M4 4.5L12 12.5' strokeWidth='2' />
			</svg>
		</button>
	);
};

export default RefinementChip;
