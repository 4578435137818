import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { Flex, Img } from '@components';
import { Paragraph, Title } from '@components/typography';
import { formatCurrency } from '@utils/shopify';
import { useBFContext } from '@context';
import { LOCALE_CODES } from '@constants';
import styles from './MyTops.module.scss';

const Trash = dynamic(() => import('@components').then(mod => mod.Trash));

const PLACEHOLDER_LIST_LENGTH = 3;

const MyTops = () => {
	const { tops, handleTop } = useBFContext();
	const { locale } = useRouter();
	const showCurr = locale === LOCALE_CODES.AU || locale === LOCALE_CODES.CA;

	return (
		<ul className={styles.list}>
			{Object.keys(tops).map((topHandle: string) => {
				return (
					<li key={`${tops[topHandle].handle}-list-item`} className={styles['list-item']}>
						<Flex align='center' justify='start' fullWidth gap={3}>
							<Flex
								align='center'
								gap={3}
								fullWidth
							>
								<img
									className={styles['list-item-image']}
									src={tops[topHandle].image.url}
									alt={tops[topHandle].name}
									width={64}
									height={32}
								/>
								<Title>{tops[topHandle].name}</Title>
							</Flex>

							<div className={styles['list-item-delete']}>
								<Trash
									callback={() => {
										handleTop(tops[topHandle]);
									}}
								/>
							</div>
							<Paragraph>
								{formatCurrency({
									amount: tops[topHandle].price.amount,
									currencyCode: tops[topHandle].price.currencyCode,
									locale,
								}, showCurr)}
							</Paragraph>
						</Flex>
					</li>
				);
			})}
			{Object.keys(tops).length < PLACEHOLDER_LIST_LENGTH &&
				new Array(PLACEHOLDER_LIST_LENGTH - Object.keys(tops).length).fill(true).map((_, index) => (
					<li key={`placeholder-${index}`} className={styles['list-placeholder']}>
						<Flex align='center' justify='start' fullWidth gap={3}>
							<Img
								className={styles['list-placeholder-image']}
								src={'/empty-selection.gif'}
								noSrcset
								alt='empty selection'
								width={64}
								height={32}
							/>
							<Title>Add a Top Frame</Title>
						</Flex>
					</li>
				))}
		</ul>
	);
};

export default MyTops;
