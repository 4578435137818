import { Flex, ProgressBar, YellowStar } from "@components";
import { Paragraph } from "@components/typography";

export const RewardsDetail = ({ rewardDetail }) => {
	let pointsToNextTier;
	try {
		pointsToNextTier = rewardDetail?.points_balance % 100;
	} catch (err) {
		console.error(err);
		return null;
	}

	return (
		<>
			<Flex align='center' gap={4}>
				<div style={{ width: '100%' }}>
					<ProgressBar width={`${pointsToNextTier}%`} />
				</div>
				<YellowStar />
			</Flex>
			<Paragraph>{`${pointsToNextTier}/100 Points Until Your Next Reward`}</Paragraph>
		</>
	)
}
