import React from 'react';
import dynamic from 'next/dynamic';
import variables from '@styles/export.module.scss';
import { Flex } from '@components';
import { Caption, Paragraph } from '@components/typography';
import { ContentfulImage } from '@ts/contentful';
import styles from './OldTestimonial.module.scss';

const DynamicImage = dynamic(() => import('next/image'), {
	ssr: false,
});

type OldTestimonialProps = {
	image: ContentfulImage;
	text: string;
	subText: string;
	featuredProductName: string;
	unoptimized: boolean; // added this for storybook next/image compatibility
};

const highlightPattern = (text, pattern) => {
	const splitText = text.split(pattern);

	if (splitText.length <= 1) {
		return text;
	}

	const matches = text.match(pattern);

	return splitText.reduce(
		(arr, element, index) =>
			matches[index]
				? [
					...arr,
					element,
					<span key={pattern} style={{ color: variables.blue2 }}>
						{matches[index]}
					</span>,
				]
				: [...arr, element],
		[]
	);
};

const OldTestimonial = ({ image, text, subText, featuredProductName, unoptimized }: OldTestimonialProps) => {
	return (
		<li className={styles.container}>
			<Flex column fullHeight>
				<div className={styles['container__image']}>
					<DynamicImage unoptimized={unoptimized ? true : false} src={image.url} alt={text} fill sizes='30rem' />
				</div>
				<Flex column justify='between' className={styles['container__text']}>
					<Paragraph style={{ padding: '1.6rem', textAlign: 'left' }}>{text}</Paragraph>
					{subText ? (
						<Caption
							small
							style={{
								padding: '1.6rem',
								textAlign: 'left',
								color: variables.gray3,
							}}
						>
							{highlightPattern(subText, featuredProductName)}
						</Caption>
					) : null}
				</Flex>
			</Flex>
		</li>
	);
};

export default OldTestimonial;
