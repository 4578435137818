import { Flex, Spacer, StatusGauge } from "@components";
import { Caption } from "@components/typography";
import variables from '@styles/export.module.scss';
import { OrderDetailPOMS } from "@ts/poms/orders";
import { ProductList } from "./ProductList";

type OrderDetailProps = {
	order: OrderDetailPOMS;
};

export const OrderTracking = ({ order }: OrderDetailProps) => {
	const { statuses, line_items } = order;
	const isCancelled = statuses.length === 2;
	const statusNumber = statuses.findLastIndex(status => status.completed) + 1;

	return (
		<>
			<StatusGauge
				status={statusNumber}
				nodes={statuses.length}
				fillColor={isCancelled ? variables.orange3 : undefined}
				horizontal
			/>
			<Spacer size={'0.8rem'} />
			<Caption small color={variables.gray4}>
				Ordered on {order?.date}
			</Caption>
			<Flex fullWidth column style={{ paddingTop: '1.6rem' }}>
				<ProductList items={line_items} />
			</Flex>
		</>
	)
};
