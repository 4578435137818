import { useRewards } from '@services/yotpo';
import AccountCard, { AccountCardProps } from '../AccountCard/AccountCard';
import styles from './RewardCard.module.scss';

const RewardCard = ({
	userId,
	...rest
}: Omit<AccountCardProps, 'children'> & { userId: string }) => {
	const { data: rewards, isLoading } = useRewards(userId);

	if (!isLoading && !rewards) return null;

	return (
		<AccountCard
			className={styles.rewardContainer}
			primaryAction='rewards'
			secondaryAction='question'
			title='MY REWARDS'
			type='rewards'
			label={`You have ${rewards?.points_balance} Rewards Points`}
			subCopy='100 points = $10 to use towards purchases!'
			rewardDetail={rewards}
			isDataLoading={isLoading}
			{...rest}
		/>
	);
};

export default RewardCard;
