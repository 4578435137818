import Link from 'next/link';
import { Img } from '@components';
import { Heading, Paragraph } from '@components/typography';
import { getArticleDate } from '@utils/blog';
import { Article } from '@ts/shopify-storefront-api';
import variables from '@styles/export.module.scss';
import styles from '../ArticleList/ArticleList.module.scss';

const ArticleCard = ({ article }) => {
	const { title, handle, image, publishedAt } = article as Article;

	return (
		<Link href={`/blogs/news/${handle}`} key={title}>
			<div className={styles.card} tabIndex={0}>
				<Img
					title={title}
					src={image.url}
					alt={title}
					width={373}
					height={308}
					style={{ borderRadius: `${variables.radius3} ${variables.radius3} 0 0` }}
				/>
				<div className={styles.childContainer}>
					<Heading tag={'h3'}>{title}</Heading>
					<Paragraph style={{ color: variables.gray3 }}>{getArticleDate(publishedAt)}</Paragraph>
				</div>
			</div>
		</Link>
	);
};

export default ArticleCard;
