import { forwardRef, useState } from 'react';
import { MEDIUM_MAX_WIDTH, PRODUCT_TYPES, MAXIMUM_VARIANTS_FOR_COLLAPSIBLE } from '@constants';
import { Button, HorizontalCard, ProductGrid, VerticalCard } from '@components';
import { Heading, Paragraph } from '@components/typography';
import { useIsMobile } from '@utils/hooks';
import { NormalizedProduct } from '@ts/product';
import { CardProps } from '@ts/index';
import styles from './AccessoryGrid.module.scss';

type AccessoryGridProps = {
	info: {
		title: string;
		subtitle: string;
		products: NormalizedProduct[];
		limitProductsShown?: boolean;
	};
};
const AccessoryGrid = forwardRef<HTMLDivElement, AccessoryGridProps>(
	({ info: { title, subtitle, products, limitProductsShown = false } }: AccessoryGridProps, ref) => {
		const isMobile = useIsMobile({ maxWidth: MEDIUM_MAX_WIDTH });
		const [expanded, setExpanded] = useState(!isMobile);

		const limitProducts = limitProductsShown && isMobile;

		return (
			<div className={styles.container} ref={ref} data-title={title}>
				<div className={styles.heading}>
					<Heading tag={'h5'}>{title}</Heading>
					<Paragraph>{subtitle}</Paragraph>
				</div>
				<ProductGrid type='accessory'>
					{products.map(product => product.variants.map((v, i) => {
						if (!expanded && i > (MAXIMUM_VARIANTS_FOR_COLLAPSIBLE - 1)) return;
						const isGiftCard = v.type === PRODUCT_TYPES.GIFT_CARD;

						const product = isGiftCard ? products[0] : v.product;
						const key = isGiftCard ? `gift-card-${v.option}` : `${v.handle}-${v.option}`;

						const cardProps: Omit<CardProps, 'children'> = {
							product: product as NormalizedProduct,
							variant: v,
							primaryAction: isGiftCard ? 'view' : 'cart',
							secondaryAction: isGiftCard ? 'none' : 'favorite',
							description: (product as NormalizedProduct)?.metafields?.shortDescription,
						};

						return isMobile ? <HorizontalCard key={key} {...cardProps} /> : <VerticalCard key={key} {...cardProps} />;
					}))}
					{limitProducts && (
						<Button
							size='small'
							onClick={() => setExpanded(!expanded)}
							linkStyle
							withChevron
							chevronDirection={expanded ? 'up' : 'down'}
							label={`Show ${expanded ? 'Less' : 'More'}`}
							style={{ margin: 'auto' }}
						/>
					)}
				</ProductGrid>
			</div>
		);
	}
);

AccessoryGrid.displayName = 'AccessoryGrid';
export default AccessoryGrid;
