/* eslint-disable max-lines */
import { memo, useEffect } from 'react';
import { useRouter } from 'next/router';
import cn from 'classnames';
import { useBFContext, useCartContext } from '@context';
import { BUNDLE_KEY_QUERY } from '@utils/constants/cart';
import {
	BASE_FRAME_NAMES,
	BUILD_FLOW_BACK_TEXT,
	BUILD_FLOW_NEXT_TEXT,
	BUILD_FLOW_NEXT_TEXT_LENS_EXPANSION,
	BUILD_FLOW_STEP_DIRECTION,
	BUILD_FLOW_STEPS,
	BUILD_FLOW_STEPS_LENS_EXPANSION,
	CUSTOMER_JOURNEYS,
	DEFAULT_BASE_FRAME_SHAPE,
	LARGE_WIDTH,
	SUBMISSION_METHODS,
	TOAST,
} from '@constants';
import { Button, Subtotal } from '@components';
import { useCart } from '@services/shopify';
import { generateBundle } from '@utils/generate-bundle';
import { useIsLensExpansionTest, useLocalStorage, useWindowDimensions } from '@utils/hooks';
import { useToastContext } from '@utils/context';
import { RX_TYPE } from '@utils/constants/base-skus';
import styles from './CartActions.module.scss';

const CartActions = ({ className = '' }: { className?: string }) => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_, setActiveFrame] = useLocalStorage<typeof BASE_FRAME_NAMES[number]>(
		'activeFrame',
		DEFAULT_BASE_FRAME_SHAPE
	);
	const { push, query, asPath } = useRouter();
	const { data: cart } = useCart();
	const { handleCartAdd, handleCartReplace } = useCartContext();
	const savedBundle = cart?.bundles && query.bundleKey ? cart?.bundles[`${query.bundleKey}`] : null;
	const { width: windowWidth } = useWindowDimensions();
	const { showToast } = useToastContext();
	const { isLensExpansion: isLensExp } = useIsLensExpansionTest();
	const isEditMode = Boolean(query?.edit);
	const demo = query.demo as string;

	// Services
	const {
		step,
		setStepChange,
		frameColor,
		frame,
		journey,
		lensColor,
		rxType,
		readerRx,
		product,
		tops,
		subtotal,
		setStep,
		BaseFrameVariant,
		setLensPackage,
		lensPackage,
		setSelectedLenses,
		setRxType,
	} = useBFContext();

	const isSunglasses = journey === CUSTOMER_JOURNEYS.SUNGLASSES;
	const isLensExpansion = isLensExp && !isSunglasses;
	const bfSteps = isLensExpansion && !isSunglasses ? BUILD_FLOW_STEPS_LENS_EXPANSION : BUILD_FLOW_STEPS;

	// Data Tags and Step Info
	const isCollectionsStep = step === bfSteps.TOP_FRAMES;
	const isPrescriptionsStep = step === bfSteps.LENS;
	const isCustomizeStep = step === bfSteps.CUSTOMIZE;
	const isSubmittedStep = step === bfSteps.SUBMIT;
	const isLensExpansionForm = (isCustomizeStep || isPrescriptionsStep || step === BUILD_FLOW_STEPS_LENS_EXPANSION.PACKAGE) && !isSunglasses;

	const backDataTag = isPrescriptionsStep ? { 'data-exit-buildflow': true } : { 'data-back': true };
	const stepDataTag = isCollectionsStep && { 'data-add-to-cart': 'build-flow' };
	const isReaderStrengthRequired = isCustomizeStep && !readerRx && rxType === RX_TYPE.READERS;
	const shouldHideChevron = !(isSubmittedStep || isEditMode) && !(windowWidth < 1115 && windowWidth >= LARGE_WIDTH);
	const defaultNextText = isLensExpansion ? BUILD_FLOW_NEXT_TEXT_LENS_EXPANSION[BUILD_FLOW_STEPS_LENS_EXPANSION.LENS] : 'Make a Selection';
	let isNextDisabled = isSubmittedStep || !rxType;

	if (isLensExpansion) isNextDisabled = isSubmittedStep || (!rxType || !lensPackage);

	useEffect(() => {
		if (step !== bfSteps.SUBMIT) return;

		const ATC = async () => {
			const lineItems = await generateBundle({
				editingBundleKey: query[BUNDLE_KEY_QUERY] as string ?? null,
				demo,
				frame,
				frameColor,
				journey,
				lensColor,
				rxType,
				readerRx,
				product,
				tops,
				submissionMethod: SUBMISSION_METHODS.REMIND,
				BaseFrameVariant,
				isLensExpansion: isLensExpansion,
				lensPackage,
			});

			try {
				const bundleKey = lineItems[0].customAttributes.find(att => att.key === '_bundle_key').value;
				if (isEditMode) {
					const lineItemIds = [
						...(!!savedBundle.base.lenses && savedBundle.base.lenses.map(lens => lens.id)),
						...(!!savedBundle.base.insurance ? [savedBundle.base.insurance.id] : []),
						...(!!savedBundle?.tops ? savedBundle.tops.map(top => top.id) : []),
						...(!!savedBundle?.other ? savedBundle.other.map(line => line.id) : []),
						savedBundle.base.frame.id,
					];

					// Adds saved Pair Care item
					if (!!savedBundle.base.insurance) {
						lineItems.push({
							id: savedBundle.base.insurance.variant.product.id,
							variant: savedBundle.base.insurance.variant,
							quantity: 1,
							customAttributes: [
								{
									key: '_bundle_key',
									value: bundleKey,
								},
							],
						});
					}
					handleCartReplace(lineItemIds, lineItems);
				} else {
					handleCartAdd(lineItems, false);
				}

				const hasParams = asPath.includes('?');

				return push({
					pathname: '/cart',
					query: {
						redirectTo: encodeURIComponent(
							`${hasParams ? asPath.split('?')[0] : asPath}?bundleKey=${query[BUNDLE_KEY_QUERY] ?? bundleKey}`
						),
					},
				});
			} catch (error) {
				console.error(error);
			}
		};

		ATC();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [step]);

	function handleStepNavigation({ type }) {
		const isSunglasses = journey === CUSTOMER_JOURNEYS.SUNGLASSES;
		if (type === 'SUBMIT') {
			setStep(bfSteps.SUBMIT);
			setActiveFrame(frame);
		}
		if (type === 'NEXT') {
			// This moves the BF steps to go to TOP FRAMES is the user has selected a lens package and rx type
			if (isLensExpansion && !!rxType && !!lensPackage && isLensExpansionForm && !isSunglasses) {
				setStep(bfSteps.TOP_FRAMES);
				return;
			}
			// since reader already preselected "standard" as lens package, we can skip the lens step
			if (rxType === RX_TYPE.READERS) {
				setStep(bfSteps.TOP_FRAMES);
				return;
			}

			setStepChange(BUILD_FLOW_STEP_DIRECTION.NEXT);
		}
		if (type === 'CLOSE' || type === 'PREVIOUS') {
			if (isLensExpansion && !isSunglasses) {
				if (isCustomizeStep) {
					setSelectedLenses([]);
					setLensPackage(null);
				}

				if (step === BUILD_FLOW_STEPS_LENS_EXPANSION.PACKAGE) {
					setRxType(null);
				}
			}

			setStepChange(BUILD_FLOW_STEP_DIRECTION.PREV);
		}
	}

	function getBackButtonText(currentStep) {
		if (currentStep <= bfSteps.LENS) {
			return BUILD_FLOW_BACK_TEXT.EXIT;
		}

		return BUILD_FLOW_BACK_TEXT.BACK;
	}

	function getNextButtonDataTag(currentStep: BUILD_FLOW_STEPS | BUILD_FLOW_STEPS_LENS_EXPANSION) {
		if (journey !== CUSTOMER_JOURNEYS.EYEGLASSES && !isLensExpansion && currentStep === bfSteps.LENS) {
			return bfSteps[bfSteps.TOP_FRAMES];
		}
		return bfSteps[currentStep + 1];
	}

	function getNextButtonText(currentStep, isEditing) {
		if (isEditing && currentStep === bfSteps.TOP_FRAMES) {
			return BUILD_FLOW_NEXT_TEXT.EDIT_MODE;
		}

		const nextText = isLensExpansion ? BUILD_FLOW_NEXT_TEXT_LENS_EXPANSION : BUILD_FLOW_NEXT_TEXT;

		if (journey === CUSTOMER_JOURNEYS.SUNGLASSES && currentStep === bfSteps.LENS) {
			return nextText[bfSteps.CUSTOMIZE];
		}

		if (isLensExpansion && !!rxType && !!lensPackage && isLensExpansionForm) {
			return nextText[bfSteps.CUSTOMIZE];
		}

		return nextText[currentStep];
	}

	return (
		<>
			<div className={cn(styles['cart-actions'], className)}>
				<Subtotal subtotal={subtotal} />
				<div className={styles['button-container']}>
					<Button
						size='small'
						color='white'
						extraClasses={styles['prev']}
						disabled={isSubmittedStep}
						withChevron={true}
						chevronDirection='left'
						chevronPosition='front'
						label={getBackButtonText(step)}
						onClick={() => handleStepNavigation({ type: isPrescriptionsStep ? 'CLOSE' : 'PREVIOUS' })}
						{...backDataTag}
					/>
					<Button
						size='small'
						color='green'
						fullWidth
						extraClasses={`${styles['next']}`}
						disabled={isNextDisabled}
						withChevron={shouldHideChevron}
						chevronDirection='right'
						label={rxType ? getNextButtonText(step, isEditMode) : defaultNextText}
						onClick={() =>
							isReaderStrengthRequired
								? showToast(TOAST.READERS_STRENGTH_ERROR)
								: handleStepNavigation({ type: isCollectionsStep ? 'SUBMIT' : 'NEXT' })
						}
						style={{ justifyContent: 'center' }}
						data-go-to-step={getNextButtonDataTag(step)}
						{...stepDataTag}
					/>
				</div>
			</div>
		</>
	);
};

CartActions.displayName = 'CartActions';

export default memo(CartActions);
