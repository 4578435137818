import { m, useReducedMotion } from 'framer-motion';
import { useRouter } from 'next/router';
import { BlueLight, Flex, LightResponsive, Lozenge, PremiumPlus } from '@components';
import { Paragraph, Title } from '@components/typography';
import { formatCurrency } from '@utils/shopify';
import { useBFContext } from '@context';
import { RX_TYPE, BASE_FRAME_LENS_OPTIONS } from '@utils/constants/base-skus';
import { getVariantPriceDiff } from '@services/shopify/hooks/useBaseFrameVariant';
import { LensInfo } from '@ts/index';
import { LOCALE_CODES, LOCALE_DICT, buildFlowLensVariants } from '@utils/index';
import variables from '@styles/export.module.scss';
import styles from '../PrescriptionStep/PrescriptionStep.module.scss';

type LensCardProps = {
	lens: LensInfo,
	index: number,
	getEducationTabs: () => any
}

const LensIcons = {
	'Blue Light': ({ className }) => <BlueLight className={className} />,
	'Light Responsive': ({ className }) => <LightResponsive className={className} />,
	'Premium Plus': ({ className }) => <PremiumPlus className={className} />,
}

const Lens = ({ lens, index, getEducationTabs }: LensCardProps) => {
	const { variantPrices, BaseFrameVariant, selectedLenses, handleCustomLens, rxType } = useBFContext();
	const shouldReduceMotion = useReducedMotion();
	const isReadersSelected = rxType === RX_TYPE.READERS;
	const isBlueLightOption = lens.optionValue === BASE_FRAME_LENS_OPTIONS.BLUE_LIGHT;
	const { locale } = useRouter();
	const showCurr = locale === LOCALE_CODES.AU || locale === LOCALE_CODES.CA;
	const isLightResponsiveSelected = selectedLenses.includes(BASE_FRAME_LENS_OPTIONS.LIGHT_RESPONSIVE);
	const lensIsSelected = (selectedLenses.includes(lens.optionValue)) || (isLightResponsiveSelected && isBlueLightOption);

	const variantPriceDiff = getVariantPriceDiff({
		lookup: variantPrices,
		current: BaseFrameVariant,
		selectedLenses,
		lens: lens.optionValue,
		currencyCode: LOCALE_DICT[locale].currencyCode,
		locale,
	});

	if (!variantPriceDiff) {
		return null
	}

	return <m.li
		key={lens.optionValue}
		variants={buildFlowLensVariants(isReadersSelected, shouldReduceMotion)}
		style={{ zIndex: 9 - index }}
		custom={{ isReadersSelected }}
		data-lens-type={lens.handle}
	>
		<Flex data-text-animate>
			<div
				key={lens.handle}
				className={styles['rx-list-item']}
				onClick={() => handleCustomLens(lens.optionValue)}
			>
				<Flex fullHeight fullWidth>
					{LensIcons[lens.optionValue]({ className: styles.rxIcon })}

					<Flex
						align='center'
						gap={2}
						fullWidth
						style={{ flexGrow: 1, backgroundColor: 'transparent', padding: '1.2rem' }}
					>
						<Flex column gap={1} align='start' style={{ flexGrow: 1 }}>
							<Flex align='center' gap={2} position={'relative'} fullWidth>
								<Lozenge text={lensIsSelected ? isBlueLightOption && isLightResponsiveSelected ? 'Included' : 'Selected' : lens.tag} shape='square' backgroundColor={lensIsSelected ? variables.greenLight : variables.gray2} color={lensIsSelected ? variables.green2 : variables.gray4} />
								<div style={{ flexGrow: 1 }} />
								<Paragraph className={styles.price} style={{ color: variables.gray4 }}>
									+
									{isBlueLightOption && isLightResponsiveSelected
										? formatCurrency({
											amount: 0,
											currencyCode: LOCALE_DICT[locale].currencyCode,
											locale,
										}, showCurr)
										: BaseFrameVariant.data.variantBySelectedOptions.title.includes(lens.optionValue)
											? formatCurrency(variantPriceDiff.without, showCurr)
											: formatCurrency(variantPriceDiff.with, showCurr)
									}
								</Paragraph>
							</Flex>
							<Title>{lens.name}</Title>
							<Paragraph>{lens.description}</Paragraph>
						</Flex>
					</Flex>

				</Flex>
			</div>
		</Flex>
	</m.li>
}

export default Lens;
