/* eslint-disable @next/next/no-img-element */
import { Flex, CutPaper } from '@components';
import { Title } from '@components/typography';
import { generateImgSrc } from '@utils/images';
import styles from '../CollectionStep/CollectionStep.module.scss';

function CollectionBanner({ collection, cutPaperPath }) {
	return (
		<Flex align='center' justify='center' className={styles['group-header']}>
			<img
				src={generateImgSrc(collection.image.url, [767])}
				alt={`${collection.title} Banner`}
				width={collection.image.width}
				height={collection.image.height}
				className={styles['banner-image']}
			/>
			<CutPaper cutPaperPath={cutPaperPath} backgroundColor='white' padding={'4px 10px 6px'}>
				<Title>{`${collection.title} Collection`}</Title>
			</CutPaper>
		</Flex>
	);
}

export default CollectionBanner;
