import { CutPaper } from '@components';
import { Heading } from '@components/typography';
import createCutPaperPath from '@utils/create-cut-paper';
import colors from '@styles/export.module.scss';
import styles from './BaseFrameHeading.module.scss';

type Props = {
	title: string;
	paperColor?: string;
};

const BaseFrameHeading = ({ title, paperColor = colors.blue2 }: Props) => {
	const path1 = createCutPaperPath('apple');
	const path2 = createCutPaperPath('banana');

	return (
		<div className={styles.container}>
			<CutPaper padding={'4px 3px 8px 8px'} cutPaperPath={path1} backgroundColor={paperColor}>
				<CutPaper padding={'1px 4px 4px 6px'} cutPaperPath={path2}>
					<Heading tag='h3' style={{ margin: '0' }}>
						{title}
					</Heading>
				</CutPaper>
			</CutPaper>
			<Heading className={styles.subHeader} style={{ maxWidth: '32rem', marginTop: '2.4rem' }}>
				{'Get started by selecting a '}
				<wbr />
				{'base frame below'}
			</Heading>
		</div>
	);
};

export default BaseFrameHeading;
