import { useRef, useEffect } from 'react';
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';
import { useBFContext } from '@context';
import { BUILD_FLOW_STEPS_LENS_EXPANSION, LENSES_PACKAGES } from '@constants';
import { RX_TYPE } from '@utils/constants/base-skus';
import { LensOption } from '@ts/components';
import { useIsLensExpansionTest } from '@utils/hooks';
import { CustomLenses, LensPackages, PrescriptionLensExpansion } from '@components';

const PrescriptionsLensExpansion = () => {
	const {
		rxType,
		readerRx,
		step,
		lensOptions,
		lensPackage,
		setLensPackage,
		setStep,
	} = useBFContext();
	const { isLensExpansion } = useIsLensExpansionTest();
	const packageStepRef = useRef<HTMLDivElement>(null);
	const customizeStepRef = useRef<HTMLDivElement>(null);
	const isPrescriptionsStep = step === BUILD_FLOW_STEPS_LENS_EXPANSION.LENS || step === BUILD_FLOW_STEPS_LENS_EXPANSION.PACKAGE || step === BUILD_FLOW_STEPS_LENS_EXPANSION.CUSTOMIZE;
	const isReadersSelected = rxType === RX_TYPE.READERS;

	useEffect(() => {
		if (isReadersSelected && readerRx) {
			if (!lensPackage) setLensPackage(LENSES_PACKAGES.STANDARD);
			setStep(BUILD_FLOW_STEPS_LENS_EXPANSION.CUSTOMIZE);
		}
	}, [rxType, lensPackage, isReadersSelected, setLensPackage, readerRx]);

	if (isLensExpansion && !isPrescriptionsStep && !isReadersSelected) return null;

	const mappedPrescriptions = () => lensOptions.map((option: LensOption, index: number) =>
		<PrescriptionLensExpansion
			key={option.id}
			option={option}
			index={index}
		/>);

	if (!isPrescriptionsStep && !isReadersSelected) return null;

	return (
		<RadioGroupPrimitive.Root asChild value={rxType}>
			<>
				{mappedPrescriptions()}
				{isLensExpansion && step === BUILD_FLOW_STEPS_LENS_EXPANSION.LENS && !!rxType && (
					<>
						<LensPackages ref={packageStepRef} customizeStepRef={customizeStepRef} />
						{!!lensPackage && <CustomLenses ref={customizeStepRef} />}
					</>
				)}
			</>
		</RadioGroupPrimitive.Root >
	);
};

export default PrescriptionsLensExpansion;
