import React from 'react';
import * as AccessibleIcon from '@radix-ui/react-accessible-icon';

const Bag = () => {
	return (
		<AccessibleIcon.Root label='Bag'>
			<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<g id='bag' clipPath='url(#clip0_2633_19593)'>
					<path id='Rectangle 6' d='M3 7H17V15C17 16.1046 16.1046 17 15 17H5C3.89543 17 3 16.1046 3 15V7Z' stroke='#9B9A9A' strokeWidth='1.5' />
					<path id='Vector 5' d='M7 9.5V6C7 4.34315 8.34315 3 10 3V3C11.6569 3 13 4.34315 13 6V9.5' stroke='#9B9A9A' strokeWidth='1.5' />
				</g>
				<defs>
					<clipPath id='clip0_2633_19593'>
						<rect width='20' height='20' fill='white' />
					</clipPath>
				</defs>
			</svg>
		</AccessibleIcon.Root>
	);
};

export default Bag;
