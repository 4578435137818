import React, { useState } from 'react';
import { useRouter } from 'next/router';
import { SubmitHandler, useForm } from 'react-hook-form';
import { MIN_VALUE } from '@constants';
import { Alert, Button, Input } from '@components';
import { Heading } from '@components/typography';
import { resetPassword } from '@services/shopify';
import styles from './LoginForm.module.scss';

type ResetPasswordFormValues = {
	password: string;
	confirmPassword: string;
};

const ResetPasswordForm = () => {
	const router = useRouter();

	const [customerId, resetToken] = router.query.urlInfo;

	const {
		register,
		reset,
		handleSubmit,
		watch,
		formState: { errors },
	} = useForm<ResetPasswordFormValues>({
		mode: 'onBlur',
		defaultValues: {
			password: '',
			confirmPassword: '',
		},
	});

	const [submitErrors, setSubmitErrors] = useState([]);
	const currentPasswordInput = watch('password');
	const confirmPasswordInput = watch('confirmPassword');
	const [loading, setLoading] = useState(false);

	const onSubmit: SubmitHandler<ResetPasswordFormValues> = async data => {
		const customerGID = `gid://shopify/Customer/${customerId}`;

		try {
			setLoading(true);
			await resetPassword(customerGID, { resetToken, password: data.password });
			setSubmitErrors([]);
			router.push('/account/login');
		} catch (error) {
			console.error(error);
			setLoading(false);
			reset();
			return setSubmitErrors(error.errors);
		}
	};

	return (
		<form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
			<Heading tag='h1' style={{ marginBottom: '2.4rem' }}>
				Reset account password
			</Heading>
			{!!submitErrors.length &&
				submitErrors.map(({ message }, index) => {
					return <Alert message={message.toString()} key={index} />;
				})}
			<Input
				{...register('password', {
					required: 'Please fill out this field.',
					minLength: {
						value: MIN_VALUE,
						message: `Please lengthen this text to 5 characters or more (you are currently using ${currentPasswordInput.length} characters).`,
					},
				})}
				errorMessage={errors?.password?.message}
				id='ResetCustomerPassword'
				name='password'
				placeholder='Password'
				type='password'
				withIcon
			/>
			<Input
				{...register('confirmPassword', {
					required: 'Please fill out this field.',
					minLength: {
						value: MIN_VALUE,
						message: `Please lengthen this text to 5 characters or more (you are currently using ${currentPasswordInput.length} characters).`,
					},
					validate: value => value === currentPasswordInput || 'Passwords do not match.',
				})}
				errorMessage={errors?.confirmPassword?.message}
				id='ResetCustomerPasswordConfirm'
				name='confirmPassword'
				placeholder='Confirm Password'
				type='password'
				withIcon
			/>

			<div className={styles.buttons}>
				<Button
					showSpinner={loading}
					data-login-button
					fullWidth
					type='submit'
					label='Reset Password'
					disabled={!(currentPasswordInput.length >= MIN_VALUE) || !(confirmPasswordInput === currentPasswordInput)}
				/>
			</div>
		</form>
	);
};

export default ResetPasswordForm;
