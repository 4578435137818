import Link from 'next/link';
import { ArticleCard, FeaturedArticle, Flex } from '@components';
import { Heading } from '@components/typography';
import { Article, Blog } from '@ts/shopify-storefront-api';
import styles from './ArticleList.module.scss';

type ArticleListProps = {
	blog: Blog & { articles: Article[] };
};

const ArticleList = ({ blog }: ArticleListProps) => {
	return (
		<Flex column className={styles.container}>
			<div className={styles.gridContainer}>
				<FeaturedArticle firstArticle={blog?.articles[0]} />
				<Heading tag='h2'>{blog.title}</Heading>
				<div className={styles.grid}>
					{blog?.articles.slice(1).map(article => {
						return (
							<Link href={article.handle} key={article.title}>
								<ArticleCard article={article} />
							</Link>
						);
					})}
				</div>
			</div>
		</Flex>
	);
};

export default ArticleList;
