import Link from 'next/link';
import { Flex, Img, Lozenge } from '@components';
import { Heading, Paragraph } from '@components/typography';
import { getArticleDate } from '@utils/blog';
import { Article } from '@ts/shopify-storefront-api';
import variables from '@styles/export.module.scss';
import styles from './FeaturedArticle.module.scss';

const FeaturedArticle = ({ firstArticle }) => {
	const { title, publishedAt, tags, image, handle, excerpt } = firstArticle as Article;

	return (
		<div className={styles.anchorArticle}>
			<Link href={`/blogs/news/${handle}`}>
				<div className={styles.section}>
					<div className={styles.imageContainer}>
						<Img width={image.width} height={image.height} src={image.url} alt={image.altText} />
					</div>
					<Flex gap={4} column justify='center' className={styles.textContainer}>
						{tags.length > 0 && <Lozenge>{tags.map(tag => tag)}</Lozenge>}
						<Heading tag='h2'>{title}</Heading>
						<Paragraph>{excerpt}</Paragraph>
						<Paragraph className={styles.date} style={{ color: variables.gray3 }}>
							{getArticleDate(publishedAt)}
						</Paragraph>
					</Flex>
				</div>
			</Link>
		</div>
	);
};

export default FeaturedArticle;
