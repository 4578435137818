import React from 'react';
import styles from './ProgressBar.module.scss';

type ProgressBarProps = {
	width: string;
};

const ProgressBar = ({ width }: ProgressBarProps) => {
	return (
		<div className={styles.container}>
			<div className={styles.bar} style={{ width }}></div>
		</div>
	);
};

export default ProgressBar;
