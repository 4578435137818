import { useMemo } from 'react';
import { useFavorites } from '@services/poms';
import { BASE_FRAME_NAMES, PRODUCT_TYPES, getShopifyIdFromGID } from '@utils/index';
import { CollectionSlider } from '@components';
import useRecommend from '@services/algolia/hooks/useRecommend';
import { Favorite } from '@ts/poms';
import { useLocalStorage } from '@utils/hooks';

const ForYouCarousel = ({ customer }) => {
	const customerId = getShopifyIdFromGID(customer?.id);
	const { data: favorites, isLoading: isFavoritesLoading } = useFavorites(customerId);
	const [activeFrame] = useLocalStorage<typeof BASE_FRAME_NAMES[number]>('activeFrame', 'Larkin');
	const parsedRecommendations = [];
	const favoriteIds = useMemo(() => {
		if (!favorites) return [];
		const filteredFavoritedfavorites = favorites.filter((fav: Favorite) => fav.product.product_type.includes(PRODUCT_TYPES.TOP_FRAME) && fav.product.status === 'active')
		return filteredFavoritedfavorites.map((fav: Favorite) => fav?.variant?.admin_graphql_api_id)
	}, [favorites]);

	const [{ recommendations }] = useRecommend({
		objectIds: favoriteIds,
		types: [ 
			PRODUCT_TYPES.ACCESSORY,
			PRODUCT_TYPES.BASE_FRAME,
			PRODUCT_TYPES.GIFT_CARD,
			PRODUCT_TYPES.INSURANCE,
			PRODUCT_TYPES.LENS,
			PRODUCT_TYPES.MISC,
			PRODUCT_TYPES.PACKAGING,
			PRODUCT_TYPES.TOP_FRAME,
		],
		collectionHandle: favoriteIds?.length ? null : 'best-sellers-1',
	});

	recommendations?.forEach((recommendation) => {
		// When there are no recommendations from Algolia we fall into the default collection, 
		// the returned products from the collection contain all the available variables. 
		// When there are recommendations from Algolia they contain only one variant.
		if (recommendation.type.includes(PRODUCT_TYPES.TOP_FRAME) && recommendation?.variants?.length > 1) {
			const variants = recommendation.variants.filter((variant) => variant.option === activeFrame);
			recommendation = { ...recommendation, variants: variants }
		}
		parsedRecommendations.push(recommendation);
	});	

	return parsedRecommendations && <CollectionSlider
		iconType='star'
		header='Looks We Think You’ll Love'
		subHeader='These Tops were made for you!'
		products={parsedRecommendations}
		limit={8}
		isDataLoading={isFavoritesLoading}
		sliderType={'recommendations'}
	/>
};

export default ForYouCarousel;
