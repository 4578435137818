const Readers = ({ className }) => {
	return <svg viewBox='0 0 96 96' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
		<path fillRule='evenodd' clipRule='evenodd' d='M48.3291 16.0064C33.8558 15.9084 20.1663 16.8495 14.6281 25.4084C11.7674 29.8302 11.5094 37.8759 12.5848 44.4342C14.8737 58.3939 20.5471 67.2209 33.968 70.4769C38.7646 71.6418 45.3523 72.3952 51.1782 71.7804C55.2812 71.3478 58.8624 71.1481 62.2296 70.1996C72.3102 67.3623 77.4291 59.2676 81.0227 50.1753C83.7289 43.3285 85.8471 32.7811 81.5407 26.0185C77.1461 19.1163 66.9437 17.5992 56.6176 16.3946L48.3291 16.0064Z' fill='#B5DEF4'/>
		<g opacity='0.4'>
			<path opacity='0.6' fillRule='evenodd' clipRule='evenodd' d='M79.128 54.3758L56.8428 16.4912C58.4378 16.6821 60.0266 16.8834 61.5885 17.1138L80.9426 50.0155C80.8504 50.2639 80.7572 50.5081 80.6633 50.7479C80.1815 51.9786 79.6725 53.1913 79.128 54.3758Z' fill='white'/>
			<path opacity='0.6' fillRule='evenodd' clipRule='evenodd' d='M78.754 53.8846L56.4688 16C58.0638 16.1909 59.6526 16.3922 61.2145 16.6226L80.5686 49.5243C80.4764 49.7727 80.3832 50.0169 80.2893 50.2567C79.8075 51.4874 79.2985 52.7001 78.754 53.8846Z' fill='white'/>
			<path opacity='0.8' fillRule='evenodd' clipRule='evenodd' d='M65.5691 69.6647L34.2666 16.4509C38.5154 16.0504 43.0854 15.9747 47.7358 16.0065L47.8508 16.0119L74.8107 61.8433C72.3903 65.1069 69.4029 67.8306 65.5691 69.6647Z' fill='white'/>
		</g>
		<path d='M74 86C74 87.1046 62.1355 88 47.5 88C32.8645 88 21 87.1046 21 86C21 84.8954 32.8645 84 47.5 84C62.1355 84 74 84.8954 74 86Z' fill='#EFEDEE'/>
		<path fillRule='evenodd' clipRule='evenodd' d='M41.39 71.7218L40.2125 69.0031L40.4041 68.9201C40.7246 68.7813 40.8719 68.4089 40.7331 68.0884L32.0617 48.0673C31.784 47.4263 31.0393 47.1317 30.3983 47.4093L15.05 54.0569C18.2491 62.5126 23.9503 68.0468 33.9679 70.4772C36.1511 71.0074 38.7054 71.4523 41.39 71.7218Z' fill='black'/>
		<path d='M23.2558 56.5979C22.7413 54.9291 23.6771 53.1591 25.346 52.6446C27.0148 52.1302 28.7848 53.066 29.2993 54.7348C29.8137 56.4037 28.8779 58.1737 27.2091 58.6881C25.5402 59.2026 23.7702 58.2668 23.2558 56.5979Z' fill='#CB3800'/>
		<path d='M20.5161 62.737L35.0205 58.2656L35.3931 59.4743L20.8887 63.9457L20.5161 62.737Z' fill='black'/>
		<path d='M21.2612 65.1543L28.5134 62.9186L28.8861 64.1273L21.6338 66.363L21.2612 65.1543Z' fill='#CB3800'/>
		<path d='M18.2168 73.0151L35.0462 65.7261L36.8056 69.7884L19.9762 77.0774L18.2168 73.0151Z' fill='#F9F9F9'/>
		<path d='M18.6899 77.29L8.25904 53.2065C7.98141 52.5655 8.27599 51.8208 8.917 51.5432L25.3576 44.4226C25.9986 44.1449 26.7433 44.4395 27.0209 45.0805L35.6924 65.1017C35.8312 65.4222 35.6839 65.7945 35.3634 65.9333L19.251 72.9118C18.7703 73.1201 18.5493 73.6786 18.7576 74.1593L19.5116 75.9003C19.7198 76.3811 20.2784 76.602 20.7591 76.3938L37.1616 69.2896C37.3219 69.2202 37.5081 69.2938 37.5775 69.4541L37.8288 70.0344C37.8982 70.1947 37.8246 70.3808 37.6643 70.4502L20.3533 77.9479C19.7122 78.2256 18.9675 77.931 18.6899 77.29Z' fill='#FF510E'/>
		<path d='M19.8799 73.6733L35.5486 66.887L35.8 67.4673L20.1312 74.2537L19.8799 73.6733Z' fill='#EFEDEE'/>
		<path d='M19.8799 73.6733L35.5486 66.887L35.8 67.4673L20.1312 74.2537L19.8799 73.6733Z' fill='black' fillOpacity='0.05'/>
		<path d='M20.3828 74.834L36.0515 68.0476L36.3029 68.628L20.6342 75.4143L20.3828 74.834Z' fill='#EFEDEE'/>
		<path d='M20.3828 74.834L36.0515 68.0476L36.3029 68.628L20.6342 75.4143L20.3828 74.834Z' fill='black' fillOpacity='0.05'/>
		<path d='M17.6586 57.4055C16.9645 55.803 17.701 53.9412 19.3035 53.2472C20.906 52.5531 22.7677 53.2895 23.4618 54.8921C24.1559 56.4946 23.4195 58.3563 21.8169 59.0504C20.2144 59.7445 18.3527 59.008 17.6586 57.4055Z' fill='#CB3800'/>
		<path d='M15.6074 63.8076L29.5352 57.7753L30.0379 58.9359L16.1101 64.9683L15.6074 63.8076Z' fill='#CB3800'/>
		<path d='M16.6128 66.1289L23.5767 63.1127L24.0794 64.2734L17.1155 67.2896L16.6128 66.1289Z' fill='#CB3800'/>
		<path d='M25.1671 73.1699L24.9745 73.2495C24.8444 73.3032 24.6955 73.2414 24.6417 73.1114C24.588 72.9814 24.6498 72.8324 24.7798 72.7787L29.7235 70.7352C30.7636 70.3052 31.9553 70.7999 32.3852 71.84L34.3885 76.6864C34.449 76.8328 34.3267 76.9892 34.17 76.9658L31.588 76.5796L29.9783 78.7489C29.9077 78.8441 29.7602 78.8258 29.7149 78.7163L27.8893 74.2998C27.4496 73.236 26.2308 72.7302 25.1671 73.1699Z' fill='#CB3800'/>
		<path d='M4.42592 60.7629L4.53859 60.822C5.84974 61.5092 7.32994 61.8081 8.80508 61.6834C8.23192 63.0473 8.06046 64.5464 8.31088 66.0046L8.3324 66.1299L8.21973 66.0708C6.90858 65.3835 5.42839 65.0847 3.95324 65.2094C4.52641 63.8455 4.69786 62.3463 4.44744 60.8882L4.42592 60.7629Z' fill='#B5DEF4'/>
		<path d='M6.29457 70.1212L6.49403 70.2258C8.81514 71.4424 11.4355 71.9715 14.0469 71.7508C13.0323 74.1652 12.7288 76.8192 13.1721 79.4005L13.2102 79.6223L13.0107 79.5177C10.6896 78.3011 8.06922 77.772 5.4578 77.9927C6.47246 75.5783 6.77598 72.9243 6.33266 70.343L6.29457 70.1212Z' fill='#B5DEF4'/>
		<path d='M15.6472 79.197L15.7237 79.2371C16.614 79.7037 17.6191 79.9067 18.6207 79.822C18.2315 80.7481 18.1151 81.766 18.2851 82.7561L18.2997 82.8412L18.2232 82.8011C17.333 82.3344 16.3279 82.1315 15.3263 82.2161C15.7154 81.2901 15.8319 80.2721 15.6618 79.2821L15.6472 79.197Z' fill='#FF510E'/>
	</svg>
	
}

export default Readers;
