import { Flex, LineItemCard } from "@components";
import { Paragraph } from "@components/typography";
import { LineItemPOMS } from "@ts/poms/orders";
import styles from './AccountCard.module.scss';

type ProductListProps = {
	items: LineItemPOMS[];
};

export const ProductList = ({ items }: ProductListProps) => {

	const mappedLineItems = items.slice(0, 2).map(item => (
		<LineItemCard key={`line-item-${item.id}`} data={item} dataSource={'poms'} noInteraction />
	));

	const onlyOneItem = items.length - 2 === 1;
	return (
		<div>
			<Flex column gap={3}>
				{mappedLineItems}
				{items.length > 2 && (
					<Paragraph className={styles.remainingItems}>{`+ ${items.length - 2} ${onlyOneItem ? 'Item' : 'Items'} `}</Paragraph>
				)}
			</Flex>
		</div>
	)
};
