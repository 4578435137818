import { memo, useEffect, useRef } from 'react';
import cn from 'classnames';
import { Paragraph } from '@components/typography';
import { NormalizedBuildFlowCollection } from '@ts/product';
import styles from '../CollectionStep/CollectionStep.module.scss';

function ScrollBar({ collections, currentGroupIndex, handleSelectCollection }) {
	const scrollBarRefs = useRef([]);

	useEffect(() => {
		const target: HTMLElement = scrollBarRefs.current[currentGroupIndex];
		target?.scrollIntoView();
	}, [currentGroupIndex]);

	return (
		<ul className={styles['navbar-scroll']}>
			{(collections as NormalizedBuildFlowCollection[]).map(({ title, products }, index: number) => {
				if (products.length < 1) {
					return null
				}
				return (
					<li
						key={`${title + index}__navbar`}
						ref={el => (scrollBarRefs.current[index] = el)}
						className={cn(styles['nav-item'], { [styles.active]: currentGroupIndex === index })}
						onClick={() => handleSelectCollection(index)}
						data-scroll-to={title}
					>
						<Paragraph>{title}</Paragraph>
					</li>
				);
			})}
		</ul>
	);
}

export default memo(ScrollBar);
