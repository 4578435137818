import { useState, useEffect, useReducer } from 'react';
import Script from 'next/script';
import { BaseFrameState, baseFrameReducer } from '@utils/hooks/useBaseFrame';
import { Button, EyeIcon, Flex, Modal, VTO } from '@components';
import { MEDIUM_WIDTH, VTO_SRC, FRAME_COLORS } from '@constants';
import { useIsMobile } from '@utils/hooks';
import { getAllBaseFrames } from "@services/contentful";
import { NormalizedCollection } from "@ts/product";

function VTOModal({ text, size, color, extraClasses = '', showIcon = false, showBaseFrameOption = true }) {
	const [collection, setCollection] = useState(null);
	const isMobile = useIsMobile({ maxWidth: MEDIUM_WIDTH });
	const buttonWidth = isMobile ? '31.1rem' : '14.7rem';

	useEffect(() => {
		(async () => {
			const collection = await getAllBaseFrames(true, {
				skipVariants: false,
				skipImages: false,
			}) as NormalizedCollection;
			setCollection(collection);
			dispatch({
				type: 'product',
				option: { product: collection?.products[0], variantIdentifier: collection?.products[0].variants[0].option },
			});
		})();
	}, []);

	const initialVtoState: BaseFrameState = {
		product: collection?.products[0],
		variant: collection?.products[0].variants[0],
		frame: collection?.products[0].variants[0].option as FRAME_COLORS,
		lens: null,
		image: collection?.products[0].variants[0].image,
	};

	const [vtoState, dispatch] = useReducer(baseFrameReducer, initialVtoState);
	const [FitMix, setFitMix] = useState(typeof window === 'undefined' ? null : window?.FitMix);

	return (
		<Modal>
			<Script
				src={VTO_SRC}
				strategy='lazyOnload'
				type='text/javascript'
				onLoad={() => {
					setFitMix(window.FitMix);
				}}
			/>
			<Modal.Trigger asChild>
				<Button
					style={{ width: buttonWidth }}
					extraClasses={extraClasses}
					label={text}
					color={color}
					size={size}
					fullWidth
					data-pdp-vto={!showBaseFrameOption && true}
					disabled={!collection}
				>
					{showIcon && (
						<Flex align='center' justify='center' gap={2}>
							<EyeIcon />
							{text}
						</Flex>
					)}
				</Button>
			</Modal.Trigger>
			<Modal.Content
				removePadding
				customClose
				onCloseAutoFocus={e => e.preventDefault()}
				style={{ maxHeight: 'fit-content', maxWidth: '64rem' }}
			>
				<VTO
					FM={FitMix}
					state={vtoState}
					setState={dispatch}
					products={collection?.products}
					pathInfo='/eyeglasses/all'
					showBaseFrameOption={showBaseFrameOption}
				/>
			</Modal.Content>
		</Modal>
	);
}

export default VTOModal;
