import React from 'react';
import variables from '@styles/export.module.scss';
import { Checkmark, Flex } from '@components';
import { Body } from '@components/typography';
import styles from './ChecklistItem.module.scss';

const { green1 } = variables;

type ChecklistItemProps = {
	caption: string;
};

const ChecklistItem = ({ caption }: ChecklistItemProps) => {
	return (
		<Flex className={styles.container}>
			<Checkmark className={styles.svg} color={green1} />
			<Body className={styles.text}>{caption}</Body>
		</Flex>
	);
};

export default ChecklistItem;
