import React from 'react';
import { m } from 'framer-motion';
import variables from '@styles/export.module.scss';
import { Button, Checkmark, Container, Flex, Plus, Trash } from '@components';
import { Paragraph, Title, TypographyButton } from '@components/typography';
import { formatCurrency } from '@utils/shopify';
import { ContentfulImageTitleTextGroupFields, ContentfulImageTitleTextStack, CopyGroupFields } from '@ts/contentful';
import { BASE_FRAME_LENS_OPTIONS } from '@utils/constants/base-skus';
import styles from './LensUpsell.module.scss';

const { green2 } = variables;

type LensUpsellProps = {
	lensUpsellCopy: CopyGroupFields | ContentfulImageTitleTextGroupFields;
	forceMobileStyle?: boolean;
	isInCart?: boolean;
	lensUpsell?: BASE_FRAME_LENS_OPTIONS;
	onClick?: () => void;
	dynamicPrice?: { amount: any; locale?: string; currencyCode?: string; minDigits?: number; maxDigits?: number; };
};

const LensUpsell = ({ lensUpsellCopy, isInCart = false, lensUpsell, onClick, dynamicPrice, forceMobileStyle = false }: LensUpsellProps) => {
	const lensCopy = (lensUpsellCopy?.blocks as Array<ContentfulImageTitleTextStack>)?.find(block => block.heading.includes(lensUpsell));
	const lensImage = lensCopy?.image;

	return (
		<Container backgroundColor='white' borderRadius={3} className={`${styles.container} ${styles.upsell}`}>
			<Flex fullWidth align='center' justify='between' gap={3}>
				<Flex align='center'>
					<Flex justify='center' className={forceMobileStyle ? styles.mobileImageView : styles.upsellImage}>
						<m.div
							initial={isInCart ? 'hidden' : 'shown'}
							animate={isInCart ? 'shown' : 'hidden'}
							variants={{
								shown: { opacity: 1 },
								hidden: { opacity: 0 },
							}}
							transition={{ ease: 'easeInOut', stiffness: 256, damping: 24, duration: 0.5, mass: 1 }}
						>
							<Checkmark width={28} height={24} circle fillColor={green2} />
						</m.div>
						<img src={lensImage?.url} alt={lensImage?.fileName} />
					</Flex>
					<Flex column pad={3}>
						<Title>{lensCopy.heading}</Title>
						<m.div
							initial={isInCart ? 'expanded' : 'collapsed'}
							animate={isInCart ? 'collapsed' : 'expanded'}
							variants={{
								collapsed: { opacity: 0, y: '50%', position: 'absolute' },
								expanded: { opacity: 1, y: 0, position: 'initial' },
							}}
							transition={{ ease: 'easeInOut', stiffness: 256, damping: 24, duration: 0.5, mass: 1 }}
						>
							<Paragraph>{lensCopy.description}</Paragraph>
						</m.div>
					</Flex>
				</Flex>
				<Flex align='center' gap={3}>
					<Button onClick={onClick} extraClasses={styles.editableLensButton} padding='0' color='white'>
						{isInCart ? <Trash callback={() => null} iconOnly /> : <Plus />}
					</Button>
					<TypographyButton small className={styles.price}>
						{dynamicPrice && formatCurrency(dynamicPrice)}
					</TypographyButton>
				</Flex>
			</Flex>
		</Container>
	);
};

export default LensUpsell;
